import { Component } from '@angular/core';
import { NgClass } from '@angular/common';
import { ModalBase } from '../../../models/modal/ModalBase';

export interface ConfirmModalData {
	title: string;
	body: string;
	affirmButton?: ConfirmModalButtonOptions;
	cancelButton?: ConfirmModalButtonOptions;
	breakWords?: boolean;
}

export interface ConfirmModalButtonOptions {
	text?: string;
	icon?: string;
	style?: string;
	hidden?: boolean;
}

export enum ConfirmModalResult {
	CANCELED,
	AFFIRMATIVE
}

@Component({
	selector: 'maj-confirm-modal',
	standalone: true,
	templateUrl: './confirm-modal.component.html',
	imports: [NgClass],
	styles: [],
})
export class ConfirmModalComponent extends ModalBase<ConfirmModalData, ConfirmModalResult> {

	protected readonly ConfirmModalResult = ConfirmModalResult;

}
