import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class RevNgbDateParserFormatter extends NgbDateParserFormatter {
	readonly DELIMITER = '/';

	parse(value: string): NgbDateStruct | null {
		if (/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/g.test(value)) {
			const date = value.split(this.DELIMITER);
			return {
				month: parseInt(date[0], 10),
				day: parseInt(date[1], 10),
				year: parseInt(date[2], 10),
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
		return date ? String(date.month).padStart(2, '0') + this.DELIMITER + String(date.day).padStart(2, '0') + this.DELIMITER + date.year : '';
	}
}
