<div class="panel-body">
	<rev-ag-grid-container [agGrid]="agGrid">
		<ng-container rev-table-action-menu-left>
			<div class="e-toolbar-item" [attr.data-test-id]="'standardReferenceDataStatusSection'">
				<label>Status</label>
				<pms-enum-select-button
					name="categoryStatus"
					[(ngModel)]="statusFilter"
					[enumList]="statusFilterOptions"
					[allOption]="true"
					(valueChange)="filterTable()">
				</pms-enum-select-button>
				@if (statusFilter.value !== null) {
					<div class="e-toolbar-item">(Re-ordering disabled while filtering)</div>
				}
			</div>
		</ng-container>
		<ng-container rev-container-grid>
			<ag-grid-angular
				#agGrid
				pmsAgAutoFitColumns
				class="ag-theme-alpine"
				rowClass="row-link"
				(rowClicked)="openReferenceDataDetails($event.data)"
				(rowDragEnd)="reorderReferenceData()"
				(gridReady)="filterTable()"
				[attr.data-test-id]="'standardReferenceDataTable'"
				[rowData]="referenceDataList"
				[pagination]="false"
				[rowDragManaged]="true"
				[loading]="isSearching"
				[columnDefs]="gridColumns">
			</ag-grid-angular>
		</ng-container>
	</rev-ag-grid-container>
</div>
