import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { ReferenceDataResponseForTable } from '@core/reference-data/reference-data.service';
import { ReferenceDataMasterCategory } from '@gandalf/constants';
import { ReferenceDataListComponent } from './reference-data-list/reference-data-list.component';

@Component({
	selector: 'pms-standard-reference-data',
	templateUrl: './standard-reference-data.component.html',
	styles: [],
})
export class StandardReferenceDataComponent implements OnInit {

	@Input()
	category: ReferenceDataMasterCategory;

	@Input()
	title: string;

	@Input()
	detailHeader = '';

	@ViewChild('list')
	list: ReferenceDataListComponent;

	referenceData: ReferenceDataResponseForTable;
	detailMode = false;

	ngOnInit(): void {
		if (_isNil(this.detailHeader) || this.detailHeader === '') {
			this.detailHeader = this.title;
		}
	}

	openReferenceData(referenceData: ReferenceDataResponseForTable) {
		this.referenceData = referenceData;
		this.detailMode = true;
	}

	closeReferenceData(refreshRequested: boolean) {
		this.referenceData = null;
		this.detailMode = false;
		if (refreshRequested) {
			this.list.getReferenceDataList();
		}
	}
}
