import { AccountingService } from '@accounting/core/accounting/accounting.service';
// eslint-disable-next-line max-len
import {
	ReceivePaymentsTransferItemsModalResponse
} from '@accounting/invoices/receive-payment-transfer-items-modal/receive-payments-transfer-items-modal.component';
import { InvoicePayment, PaymentItemPermissions, ReceivePaymentsService } from '@accounting/invoices/receive-payments/receive-payments.service';
import { SelectInvoiceModalComponent } from '@accounting/invoices/select-invoice-modal/select-invoice-modal.component';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { PaymentComponentService, PaymentGroupRequest } from '@core/accounting/payment/payment-component.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _clamp, _find, _findIndex, _isNil, _some } from '@core/lodash/lodash';
import { DialogUtil, EnumUtil, GridUtil, GridUtilService, LoadingOverlayMethod, ModalManagerService, OptionItem, TemplateCellRendererComponent } from 'morgana';
import { OpenEdgePaymentService } from '@core/open-edge-payment/open-edge-payment.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { CreatePaymentGroupRequest } from '@gandalf/model/create-payment-group-request';
import { CreatePaymentRequest } from '@gandalf/model/create-payment-request';
import { UpdatePaymentGroupRequest } from '@gandalf/model/update-payment-group-request';
import {
	AccountingPaymentByItemPreferenceValues,
	CreditCardType,
	PaymentGroupSourceType,
	PaymentMethodType,
	PreferenceName,
	MasterSecureResourceId
} from '@gandalf/constants';
import { AccountingPaymentPreferencesResponse } from '@gandalf/model/accounting-payment-preferences-response';
import { ReceivePaymentState } from '@payments-store/reducers/payment-tab.reducer';
import { BaseComponent } from '@shared/component/base.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { FormMode } from '@shared/constants/form-mode.enum';
import { assertTrue } from '@shared/validators/assert-true.validation';
import { SelectEventArgs } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base';
import { AgGridAngular } from 'ag-grid-angular';
import { CellFocusedEvent, CellPosition, Column, GridOptions, GridPreDestroyedEvent, ITooltipParams, TabToNextCellParams } from 'ag-grid-community';
import { Big } from 'big.js';
import { GandalfConstant, GandalfConstantList, GandalfFormArray, GandalfFormBuilder } from 'gandalf';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InvoiceDashboardResponse } from '@gandalf/model/invoice-dashboard-response';

export enum CreditCardInput {
	READER,
	MANUAL,
	ON_FILE,
}

@Component({
	selector: 'pms-receive-payments',
	templateUrl: './receive-payments.component.html',
})
export class ReceivePaymentsComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input()
	state: ReceivePaymentState;

	@Input()
	isModal = false;

	@Output()
	openInvoiceDetail: EventEmitter<number> = new EventEmitter();

	@ViewChild('invoicePaymentsGrid')
	invoicePaymentsGrid: AgGridAngular<InvoicePayment>;

	@ViewChild('paymentColumn')
	paymentColumn: TemplateRef<any>;

	@ViewChild('itemsColumn')
	itemsColumn: TemplateRef<any>;

	@ViewChild('newBalanceColumn')
	newBalanceColumn: TemplateRef<any>;

	@ViewChild('templateForm')
	templateForm: NgForm;

	@ViewChild('writeOffReasonGrid')
	writeOffReasonGrid: AgGridAngular;

	@ViewChild('bulkWriteOffButtonTemplate')
	bulkWriteOffButtonTemplate: TemplateRef<any>;

	writeOffReasonGridOptions: GridOptions;

	payByItemPreference: AccountingPaymentByItemPreferenceValues;
	paymentItemPermissions: PaymentItemPermissions;
	allowAddWriteOff: boolean;
	paymentMethods: GandalfConstantList<PaymentMethodType>;
	creditCardTypes: GandalfConstantList<CreditCardType>;
	creditCardInputOptions: OptionItem[];
	payerSavedCards: OptionItem[];
	cardOnFileFeatureFlag: boolean;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	bulkWriteOffReasons: OptionItem[];
	filteredBulkWriteOffReasons: OptionItem[];
	bulkWriteOffs: OptionItem[];
	invoicePaymentsGridOptions: GridOptions<InvoicePayment>;
	paymentAmountSum: number;
	itemsTotalSum: number;
	transferTotalSum: number;
	finalBalanceSum: number;
	ignoreInvoicePaymentAmountChange: boolean;
	focusInvoicePaymentAmountId: number;
	_isLoading: boolean;
	disbursePaymentsFlag: boolean;
	hasNegativeNewBalance = false;
	firstDisplayedRowIndex: number;

	constructor(
		private securityManager: SecurityManagerService,
		private featureService: FeatureService,
		private userLocationService: UserLocationsService,
		private openEdgePaymentService: OpenEdgePaymentService,
		private modalManagerService: ModalManagerService,
		private accountingService: AccountingService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private receivePaymentsService: ReceivePaymentsService,
		public paymentComponentService: PaymentComponentService,
		private gridUtilService: GridUtilService,
	) {
		super();
	}

	ngOnInit() {
		this.initSubscriptions();
		this.initPreferencesAndPermissions();
		this.initData();
	}

	ngAfterViewInit() {
		this.initGrid();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.emitState();
	}

	initSubscriptions() {
		this.paymentComponentService.paymentSubmitted.pipe(takeUntil(this.unsubscribe)).subscribe(event => this.templateForm.onSubmit(event));
	}

	initPreferencesAndPermissions() {
		this.allowAddWriteOff = this.securityManager.hasPermission(MasterSecureResourceId.ACCOUNTING_ADD_WRITEOFF.value);
		this.cardOnFileFeatureFlag = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PATIENTS.ACCOUNT.GLOBAL_PAYMENT_RECEIVE_PAYMENT_CARD_ON_FILE);
		this.disbursePaymentsFlag = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.INVOICE.PAYMENT_E_STATEMENT);
		this.payByItemPreference = EnumUtil.findEnumByValue(
			this.securityManager.preferenceValue(PreferenceName.ACCOUNTING_PAY_BY_ITEM.value),
			AccountingPaymentByItemPreferenceValues,
		);
		this.paymentItemPermissions = this.receivePaymentsService.getPaymentItemPermissions(this.paymentGroup?.payments);
	}

	initData() {
		this.loadData().subscribe(([prefs, writeOffReasons, practiceLocationCredentials, invoicePayments]) => {
			this.setupPaymentsAndCreditCards(prefs);
			this.bulkWriteOffs = this.state?.bulkWriteOffReasons || [];
			this.bulkWriteOffReasons = writeOffReasons;
			this.filterBulkWriteOffReasons();
			this.paymentComponentService.practiceLocationCredentials = practiceLocationCredentials;
			this.paymentComponentService.invoicePayments = invoicePayments;
			this.initForm();
			this.maybeOpenSelectInvoiceModal();
		});
	}

	@LoadingOverlayMethod()
	loadData() {
		return combineLatest([
			this.accountingService.findPaymentPreferences(),
			this.accountingService.findActiveWriteoffReasonsForDropdown(),
			this.accountingService.getActivePracticeLocationsExternalCredentials(),
			this.getInvoicePayments(),
		]);
	}

	getInvoicePayments(): Observable<InvoicePayment[]> {
		if (this.hasState()) {
			return of(this.state.invoicePayments as any);
		} else if (this.allowManualSelectInvoice()) {
			return of(this.paymentGroup?.payments?.map(payment => this.receivePaymentsService.buildExistingInvoicePayment(payment, true)) || []);
		} else {
			return this.receivePaymentsService.findInvoicePayments(
				this.paymentGroup?.sourcePracticeLocationId || this.payer?.sourcePracticeLocationId,
				this.paymentGroup?.payerEntityId || this.payer?.entityId,
				this.paymentComponentService.getPayerType(),
				this.paymentGroup?.payments,
			);
		}
	}

	setupPaymentsAndCreditCards(prefs: AccountingPaymentPreferencesResponse) {
		this.paymentMethods = this.buildOrderedGandalfSubsetList(PaymentMethodType.VALUES, prefs.paymentMethods);
		this.creditCardTypes = this.buildOrderedGandalfSubsetList(CreditCardType.VALUES, prefs.creditCardTypes);
		this.paymentComponentService.creditCardTypes = this.creditCardTypes.values;
	}

	buildOrderedGandalfSubsetList<T extends GandalfConstant<any>>(constantList: GandalfConstantList<T>, expectedValues: T[]): GandalfConstantList<T> {
		return EnumUtil.buildGandalfEnumSubsetList(EnumUtil.enumOrderedSubset([...constantList.values], expectedValues), constantList.label);
	}

	/* istanbul ignore next */
	initGrid() {
		this.invoicePaymentsGridOptions = GridUtil.buildGridOptions<InvoicePayment>({
			pagination: false,
			domLayout: 'normal',
			rowSelection: 'multiple',
			suppressRowClickSelection: true,
			suppressCellFocus: true,
			rowBuffer: 0,
			onGridReady: this.refreshGrid,
			tabToNextCell: this.tabToNextCell,
			getRowId: params => params.data.invoiceId?.toString(),
			onCellFocused: this.cellFocused,
			onRowSelected: this.updateInvoicePaymentSelected,
			onFirstDataRendered: this.onFirstDataRender,
			onGridPreDestroyed: (params: GridPreDestroyedEvent<InvoicePayment>) => this.firstDisplayedRowIndex = params.api.getFirstDisplayedRowIndex(),
			isRowSelectable: (paymentRow) => _isNil(paymentRow.data.processingPaymentGroupId),
			columnDefs: [
				GridUtil.buildCheckboxSelectionColumn({
					headerCheckboxSelection: false,
					showDisabledCheckboxes: true,
					tooltipValueGetter: this.invoicePaymentTooltipValueGetter,
				}),
				GridUtil.buildHyperlinkColumn(
					'#',
					'invoiceId',
					data => this.openInvoiceDetail.emit(data.invoiceId),
					rowData => _isNil(rowData.processingPaymentGroupId),
					{
						tooltipValueGetter: this.invoicePaymentTooltipValueGetter,
					}),
				this.gridUtilService.buildDateColumn('Invoice Date', 'invoiceDate', DATE_FORMATS.MM_DD_YYYY, {
					width: 115,
					tooltipValueGetter: this.invoicePaymentTooltipValueGetter,
				}),
				this.gridUtilService.buildDateColumn('Service Date', 'serviceDate', DATE_FORMATS.MM_DD_YYYY, {
					width: 118,
					tooltipValueGetter: this.invoicePaymentTooltipValueGetter,
				}),
				GridUtil.buildColumn('Patient Name', 'patientName', {
					minWidth: 150,
					flex: 1,
					tooltipValueGetter: this.invoicePaymentTooltipValueGetter,
				}),
				this.gridUtilService.buildCurrencyColumn('Total', 'total', {
					width: 80,
					tooltipValueGetter: this.invoicePaymentTooltipValueGetter,
				}),
				this.gridUtilService.buildCurrencyColumn('Balance', 'balance', {
					width: 90,
					tooltipValueGetter: this.invoicePaymentTooltipValueGetter,
				}),
				this.gridUtilService.buildCurrencyColumn('Payment', 'paymentAmount', {
					width: 95,
					cellRenderer: TemplateCellRendererComponent,
					cellRendererParams: {
						ngTemplate: this.paymentColumn,
					},
					suppressKeyboardEvent: GridUtil.suppressKeyboardEvent,
					tooltipValueGetter: this.invoicePaymentTooltipValueGetter,
				}),
				this.gridUtilService.buildCurrencyColumn('Items', 'itemsTotal', {
					width: 85,
					cellRenderer: TemplateCellRendererComponent,
					cellRendererParams: {
						ngTemplate: this.itemsColumn,
					},
					hide: !this.paymentItemPermissions.allowPayByItem,
					tooltipValueGetter: this.invoicePaymentTooltipValueGetter,
				}),
				this.gridUtilService.buildHyperlinkCurrencyColumn(
					'Transfer',
					'transferTotal',
					data => this.openTransferModal(this.getInvoicePaymentById(data.invoiceId)),
					data => !this.isAggregateRow(data) && _isNil(data.processingPaymentGroupId),
					{
						width: 90,
						tooltipValueGetter: this.invoicePaymentTooltipValueGetter,
					},
				),
				this.gridUtilService.buildCurrencyColumn('New Balance', 'finalBalance', {
					width: 120,
					cellRenderer: TemplateCellRendererComponent,
					cellRendererParams: {
						ngTemplate: this.newBalanceColumn,
					},
					tooltipValueGetter: this.invoicePaymentTooltipValueGetter,
				}),
			],
		});

		this.writeOffReasonGridOptions = GridUtil.buildGridOptions({
			pagination: false,
			columnDefs: [
				GridUtil.buildColumn('Reason', 'label', {
					sortable: false,
					resizable: false,
					flex: 1,
				}),
				GridUtil.buildButtonColumn('', this.bulkWriteOffButtonTemplate, {
					sortable: false,
					resizable: false,
					width: 45,
				}),
			],
		});
	}

	invoicePaymentTooltipValueGetter(params: ITooltipParams<InvoicePayment>) {
		if (_isNil(params.data.processingPaymentGroupId)) {
			if ((params.column as Column).getColId() === 'patientName') {
				return params.data.patientName;
			} else {
				return '';
			}
		} else {
			return `Invoice cannot be modified. Payment #${params.data.processingPaymentGroupId} is processing.`;
		}
	}

	initForm() {
		let request: PaymentGroupRequest;
		if (_isNil(this.paymentGroup)) {
			request = new CreatePaymentGroupRequest();
			request.sourceType = this.payer.sourceType;
			request.paymentLocationId = this.userLocationService.getCurrentUserLocation().id;
			request.paymentDate = this.now();
			request.paymentMethodType = this.paymentComponentService.isInsurancePayer() ? this.payer.defaultPaymentMethod : null;
			request.payerEntityId = this.payer.entityId;
			request.sourcePracticeLocationId = this.payer.sourcePracticeLocationId;
		} else {
			request = new UpdatePaymentGroupRequest();
			(request as UpdatePaymentGroupRequest).paymentGroupId = this.paymentGroup.paymentGroupId;
			request.paymentAmount = this.paymentGroup.paymentAmount;
			request.paymentMethodType = this.paymentGroup.paymentMethodType;
			request.applyFull = this.paymentGroup.applyFull;
			request.referenceNumber = this.paymentGroup.referenceNumber;
			request.paymentDate = this.paymentGroup.paymentDate;
			request.sourcePracticeLocationId = this.paymentGroup.sourcePracticeLocationId;
			request.paymentLocationId = this.paymentGroup.paymentLocationId;
			request.creditCardType = this.paymentGroup.creditCardType;
			request.payerEntityId = this.paymentGroup.payerEntityId;
			request.sourceType = this.paymentGroup.sourceType;
			request.comment = this.paymentGroup.comment;
		}
		request.payerType = this.paymentComponentService.getPayerType();
		request.payments = [];
		this.paymentComponentService.request = request;

		this.paymentComponentService.formGroup = this.gandalfFormBuilder.group(request, {
			validators: [
				assertTrue(
					() => this.formGroup && this.assertAtLeastOneInvoicePayment(),
					[],
					'oneInvoicePaymentSelectedRequired',
					'A payment must be applied to at least one invoice',
				),
				assertTrue(
					() => this.formGroup && this.assertPaymentSumEqualsTotalPayment(),
					[],
					'sumMustMatchPaymentAmount',
					'The sum of the payments must match the payment amount',
				),
				assertTrue(
					() => this.formGroup && this.assertCardReaderIsSelectedIfUsingOpenEdge(),
					[],
					'openEdgeCardReader',
					'Card Reader is required',
				),
				assertTrue(
					() => this.formGroup && this.assertOnFileCardIsSelectedIfUsingOpenEdge(),
					[],
					'openEdgeOnFileCard',
					'On File card must be selected',
				),
				assertTrue(
					() => this.formGroup && this.assertNoCreditCardPaymentIsNegative(),
					[],
					'creditCardPaymentNegative',
					'Invoice payment may not be negative for credit card processor transactions.' +
					' Negative credit card payments can be applied by selecting' +
					' "Apply payment without processing card" when all other validations are resolved',
				),
			],
		});
		this.formGroup.addControl('writeOffReason', new UntypedFormControl(null));
		this.formGroup.addControl('selectedCreditCardInput', new UntypedFormControl(CreditCardInput.READER));
		this.formGroup.addControl('selectedOpenEdgeCardReader', new UntypedFormControl(null));
		this.formGroup.addControl('selectedOpenEdgeOnFileCard', new UntypedFormControl(null));
		this.ignoreInvoicePaymentAmountChange = true;
		this.invoicePayments.forEach(invoicePayment => this.addPaymentForm(invoicePayment));
		if (this.hasState()) {
			this.formGroup.setValue(this.state.formState.value);
		}
		FormUtilsService.reactToValueChanges(this.formGroup.get('paymentLocationId'), () => this.onPaymentLocationChange(), true, this.unsubscribe);
		FormUtilsService.reactToValueChanges(this.formGroup.get('paymentMethodType'), () => this.onPaymentMethodTypeChange(), false, this.unsubscribe);
		FormUtilsService.reactToValueChanges(this.formGroup.get('applyFull'), () => this.paymentComponentService.updateValidations(), false, this.unsubscribe);
		this.disableFormFields();
		this.ignoreInvoicePaymentAmountChange = false;
	}

	addPaymentForm(invoicePayment: InvoicePayment) {
		const paymentRequest = new CreatePaymentRequest();
		paymentRequest.invoiceId = invoicePayment.invoiceId;
		paymentRequest.amount = invoicePayment.paymentAmount || 0;
		paymentRequest.paymentTransfers = [];
		paymentRequest.paymentItems = [];

		const paymentForm = this.gandalfFormBuilder.group(paymentRequest, {
			validators: [
				assertTrue(
					() => this.formGroup && this.assertItemPaymentSumEqualsInvoicePayment(invoicePayment),
					['amount'],
					`itemPaymentMustMatchPaymentAmount${invoicePayment.invoiceId}`,
					`Invoice #${invoicePayment.invoiceId} the sum of the item payments does not equal the invoice payment`,
				),
				assertTrue(
					() => this.formGroup && this.assertInvoicePaidInFull(invoicePayment),
					['amount'],
					`itemPaymentMustPayInFull${invoicePayment.invoiceId}`,
					`Invoice #${invoicePayment.invoiceId} apply in full selected but the invoice has a balance`,
				),
				assertTrue(
					() => this.formGroup && this.assertApplyInFullNotUsingItemPayments(invoicePayment),
					[],
					`payInFullNoItemPayments${invoicePayment.invoiceId}`,
					`Invoice #${invoicePayment.invoiceId} apply in full selected but the payment has line item payments`,
				),
				assertTrue(
					() => this.formGroup && this.assertApplyInFullNotUsingItemTransfers(invoicePayment),
					[],
					`payInFullNoItemTransfers${invoicePayment.invoiceId}`,
					`Invoice #${invoicePayment.invoiceId} apply in full selected but the payment has transfer items`,
				),
				assertTrue(
					() => this.formGroup && this.assertAdjustmentLessThanOrEqualsInvoiceTotal(invoicePayment),
					[],
					`payInFullNoItemTransfers${invoicePayment.invoiceId}`,
					`Invoice #${invoicePayment.invoiceId} the sum of adjustments is greater than invoice total`,
				),
			],
		});
		FormUtilsService.reactToValueChanges(paymentForm.get('amount'), () => this.onInvoicePaymentAmountChange(invoicePayment), false, this.unsubscribe);
		this.paymentComponentService.request.payments.push(paymentRequest);
		(this.formGroup.get('payments') as GandalfFormArray).push(paymentForm);
	}

	disableFormFields() {
		if (this.paymentComponentService.isOnlinePayment) {
			this.formGroup.get('paymentAmount').disable();
			this.formGroup.get('paymentMethodType').disable();
			this.formGroup.get('creditCardType').disable();
			this.formGroup.get('referenceNumber').disable();
			this.formGroup.get('paymentDate').disable();
		}
	}

	// Checks for at least one invoice payment to be selected when applying payments
	assertAtLeastOneInvoicePayment() {
		return !this.applyingPayment || !!this.invoicePayments.find(item => item.selected);
	}

	// Checks for the sum of the invoice payments to equal the top form payment when applying payment
	assertPaymentSumEqualsTotalPayment() {
		const paymentsTotal = this.invoicePayments.reduce((total, item) => Big(total).plus(item.paymentAmount || 0), Big(0));
		return !this.applyingPayment || Big(paymentsTotal).eq(this.formGroup.get('paymentAmount').value);
	}

	assertCardReaderIsSelectedIfUsingOpenEdge() {
		return !this.applyingPayment
			|| !this.showCardReaders()
			|| !this.paymentComponentService.isCreditCardPaymentMethod()
			|| !_isNil(this.formGroup.get('selectedOpenEdgeCardReader').value);
	}

	assertOnFileCardIsSelectedIfUsingOpenEdge() {
		return !this.applyingPayment
			|| !this.showOnFileCards()
			|| !this.paymentComponentService.isCreditCardPaymentMethod()
			|| !_isNil(this.formGroup.get('selectedOpenEdgeOnFileCard').value);
	}

	assertNoCreditCardPaymentIsNegative() {
		return !this.applyingPayment
			|| this.applyingPaymentWithoutProcessing
			|| !this.paymentComponentService.isOpenEdgeActive()
			|| !this.paymentComponentService.isCreditCardPaymentMethod()
			|| !this.checkPaymentsHasNegativeAmount();
	}

	// Checks for the invoice item payments to equal the item payment if the option is required for the practice and applying payment and NOT applying in full
	assertItemPaymentSumEqualsInvoicePayment(item: InvoicePayment) {
		return !this.applyingPayment
			|| this.formGroup.get('applyFull').value
			|| !EnumUtil.equals(this.payByItemPreference, AccountingPaymentByItemPreferenceValues.REQUIRED)
			|| Big(item.paymentAmount || 0).eq(item.transfersAndPayments.itemsPaidTotal);
	}

	// Checks that the if apply in full is selected the invoice IS paid in full when applying payments, and the invoice is selected
	assertInvoicePaidInFull(item: InvoicePayment) {
		return !this.applyingPayment
			|| !this.formGroup.get('applyFull').value
			|| !item.selected
			|| Big(item.finalBalance).eq(0);
	}

	// Checks when apply in full is true, individual item payments are not being used
	assertApplyInFullNotUsingItemPayments(item: InvoicePayment) {
		return !this.applyingPayment
			|| !this.formGroup.get('applyFull').value
			|| !item.selected
			|| Big(item.transfersAndPayments.itemsPaidTotal).eq(0);
	}

	// Checks when apply in full is true, individual item transfers are not being used
	assertApplyInFullNotUsingItemTransfers(item: InvoicePayment) {
		return !this.applyingPayment
			|| !this.formGroup.get('applyFull').value
			|| !item.selected
			|| Big(item.transfersAndPayments.transferTotal).eq(0);
	}

	// Checks that the adjustment total with the transfers total is less than or equal to the total on the invoice
	assertAdjustmentLessThanOrEqualsInvoiceTotal(item: InvoicePayment) {
		const transferTotal = Big(item.transfersAndPayments.transferTotal);
		const adjustmentTotal = transferTotal.plus(item.adjustmentTotal);
		const paymentAmount = Big(item.paymentAmount || 0);
		let totalAmount = Big(item.total);
		if (paymentAmount.lt(0)) {
			totalAmount = totalAmount.sub(paymentAmount);
		}
		return !this.applyingPayment
			|| !item.selected
			|| transferTotal.eq(0)
			|| adjustmentTotal.lte(totalAmount);
	}

	confirmPaymentLocationChange(event: SelectEventArgs) {
		if (this.paymentComponentService.isOnlinePayment && event.isInteracted) {
			event.cancel = true;
			const newLocationId = event.itemData.value as any;
			const dialog = DialogUtil.confirm({
				title: 'Update Payment Location',
				content: 'Changing the payment location will not change the location in the OpenEdge View merchant portal. '
					+ 'Are you sure you want to change the payment location?',
				okButton: {
					click: () => {
						this.formGroup.get('paymentLocationId').setValue(newLocationId);
						dialog.close();
					},
				},
			});
		}
	}

	onPaymentLocationChange() {
		if (this.paymentComponentService.isOpenEdgeActive()) {
			this.updateOpenEdgeCardReaders();
		}
		this.onPaymentMethodTypeChange();
	}

	onPaymentMethodTypeChange() {
		if (this.paymentComponentService.isCreditCardPaymentMethod()) {
			this.updateCreditCardInputOptions();
			if (this.paymentComponentService.showCardProcessing()) {
				this.formGroup.get('creditCardType').setValue(null);
				this.formGroup.get('selectedCreditCardInput').setValue(CreditCardInput.READER);
			}
		}
		this.updateReferenceNumberStatus();
	}

	onInvoicePaymentAmountChange(invoicePayment: InvoicePayment) {
		if (this.ignoreInvoicePaymentAmountChange) {
			return;
		}
		invoicePayment.paymentAmount = this.getInvoicePaymentAmountControl(invoicePayment.invoiceId).value;
		// Calculate the final balance
		this.receivePaymentsService.updateInvoicePaymentBalances(invoicePayment);

		this.updateAggregateRow();
		// this row should be selected if an amount other than 0 is entered or if there are any transfers or payments saved
		if (invoicePayment.paymentAmount
			|| invoicePayment.transfersAndPayments.transferItemRequests.length
			|| invoicePayment.transfersAndPayments.paymentItemRequests.length) {
			this.invoicePaymentsGrid.api.getRowNode(invoicePayment.invoiceId.toString()).setSelected(true);
		}
	}

	disbursePaymentsCheck() {
		if (this.disbursePaymentsConditions()) {
			this.invoicePaymentsGrid.api.selectAll();
		} else if (this.disbursePaymentsItemConditions()) {
			this.invoicePaymentsGrid.api.selectAll();
			this.formGroup.get('applyFull').setValue(true);
		}
	}

	disbursePaymentsConditions() {
		return this.disbursePaymentsFlag
			&& EnumUtil.equals(this.payByItemPreference, AccountingPaymentByItemPreferenceValues.NONE)
			&& !this.hasNegativeNewBalance
			&& !_isNil(this.paymentGroup)
			&& Big(this.finalBalanceSum).eq(this.paymentGroup.paymentAmount)
			&& this.paymentComponentService.isOnlinePayment
			&& this.paymentGroup?.version === 0;
	}

	disbursePaymentsItemConditions() {
		return this.disbursePaymentsFlag
			&& EnumUtil.equalsOneOf(this.payByItemPreference, AccountingPaymentByItemPreferenceValues.OPTIONAL, AccountingPaymentByItemPreferenceValues.REQUIRED)
			&& !this.hasNegativeNewBalance
			&& !_isNil(this.paymentGroup)
			&& Big(this.finalBalanceSum).eq(this.paymentGroup.paymentAmount)
			&& this.paymentComponentService.isOnlinePayment
			&& this.paymentGroup?.version === 0;
	}

	updateInvoicePaymentSelected = (event) => {
		const invoicePayment = event.node.data;
		const selected = event.node.isSelected();
		if (invoicePayment.selected === selected) {
			return;
		}
		invoicePayment.selected = selected;
		const amountControl = this.getInvoicePaymentAmountControl(invoicePayment.invoiceId);

		if (invoicePayment.selected) {
			if (!amountControl.value) {
				const payment = invoicePayment.transfersAndPayments.paymentItemRequests?.length
					? invoicePayment.transfersAndPayments.itemsPaidTotal
					: invoicePayment.balance;
				amountControl.setValue(payment);
			}
		} else {
			invoicePayment.itemsTotal = 0;
			invoicePayment.transferTotal = 0;
			invoicePayment.paymentAmount = 0;
			invoicePayment.transfersAndPayments = this.receivePaymentsService.newTransferAndPayments();
			this.updateInvoicePaymentRow(invoicePayment, 0);
		}
		this.paymentComponentService.updateValidations();
	};

	updateOpenEdgeCardReaders() {
		this.findActiveOpenEdgeCardReaders().subscribe(readers => {
			this.paymentComponentService.openEdgeCardReaders = readers;
			const openEdgeCardReaderId = readers.length === 1
				? readers[0].openEdgeCardReaderId
				: Number(this.openEdgePaymentService.getMostRecentlyUsedCardReader());
			this.formGroup.get('selectedOpenEdgeCardReader').setValue(
				_find(this.paymentComponentService.openEdgeCardReaders, {openEdgeCardReaderId})?.openEdgeCardReaderId || null,
			);
		});
	}

	@LoadingOverlayMethod()
	findActiveOpenEdgeCardReaders() {
		const locationId = this.formGroup.get('paymentLocationId').value;
		return this.openEdgePaymentService.findActiveOpenEdgeCardReadersByLocationId(locationId);
	}

	updateCreditCardInputOptions() {
		this.creditCardInputOptions = [
			{label: 'Reader', value: CreditCardInput.READER},
			{label: 'Manual', value: CreditCardInput.MANUAL},
		];
		if (this.paymentComponentService.isOpenEdgeActive() && this.cardOnFileFeatureFlag && this.paymentComponentService.isPatientPayer()) {
			this.loadPayerSavedCards().subscribe(payerSavedCards => {
				this.payerSavedCards = payerSavedCards;
				if (this.payerSavedCards.length > 0) {
					this.creditCardInputOptions.push({label: 'On File', value: CreditCardInput.ON_FILE});
				}
			});
		}
	}

	@LoadingOverlayMethod()
	loadPayerSavedCards() {
		if (_isNil(this.payerSavedCards)) {
			return this.openEdgePaymentService.findSavedPaymentCardsByPersonIdForDropdown(this.formGroup.get('payerEntityId').value);
		} else {
			return of(this.payerSavedCards);
		}
	}

	updateReferenceNumberStatus() {
		if (this.paymentComponentService.showCardProcessing()
			&& this.paymentComponentService.isCreditCardPaymentMethod()
			&& !this.paymentComponentService.isInsurancePayer()) {
			this.formGroup.get('referenceNumber').disable();
			this.formGroup.get('referenceNumber').setValue(null);
		} else if (this.paymentComponentService.isOnlinePayment) {
			this.formGroup.get('referenceNumber').disable();
		} else {
			this.formGroup.get('referenceNumber').enable();
		}
	}

	getInvoicePaymentAmountControl(invoiceId: number) {
		const index = _findIndex(this.invoicePayments, {invoiceId});
		return this.formGroup.get(`payments.${index}.amount`) as UntypedFormControl;
	}

	showCardReaders() {
		return this.paymentComponentService.isOpenEdgeActive() && this.formGroup.get('selectedCreditCardInput').value === CreditCardInput.READER;
	}

	showOnFileCards() {
		return this.paymentComponentService.isOpenEdgeActive() && this.formGroup.get('selectedCreditCardInput').value === CreditCardInput.ON_FILE;
	}

	showBulkWriteOff() {
		// bulk write-off section does not fit in the modal
		return !this.isModal && this.paymentComponentService.isInsurancePayer() && this.paymentItemPermissions.allowTransferByItem && this.allowAddWriteOff;
	}

	addBulkWriteOff() {
		const writeOffControl = this.formGroup.get('writeOffReason');
		this.bulkWriteOffs = this.bulkWriteOffs.concat(this.bulkWriteOffReasons.find(reason => writeOffControl.value === reason.value));
		this.filterBulkWriteOffReasons();
		writeOffControl.setValue(null);
	}

	removeBulkWriteOff(removeReason: OptionItem) {
		this.bulkWriteOffs = this.bulkWriteOffs.filter(reason => reason.value !== removeReason.value);
		this.filterBulkWriteOffReasons();
	}

	checkPaymentsHasNegativeAmount() {
		let isNegative = false;
		this.invoicePayments.forEach(payment => {
			const control = this.getInvoicePaymentAmountControl(payment.invoiceId);
			if (!_isNil(control) && Big(control.value).lt(0)) {
				isNegative = true;
			}
		});
		return isNegative;
	}

	filterBulkWriteOffReasons() {
		this.filteredBulkWriteOffReasons =
			this.bulkWriteOffReasons.filter(reason => !_some(this.bulkWriteOffs, selectedReason => selectedReason.value === reason.value));
	}

	refreshGrid = () => {
		this.invoicePaymentsGrid.api.setGridOption('rowData', this.invoicePayments);
		this.invoicePaymentsGrid.api.forEachNode(node => node.setSelected(node.data.selected));
		this.updateAggregateRow();
	};

	onFirstDataRender = () => {
		if (this.hasState()) {
			this.invoicePaymentsGrid.api.ensureIndexVisible(this.state.topDisplayedRowIndex, 'top');
		} else {
			this.hasNegativeNewBalance = _some(this.invoicePayments, invoice => Big(invoice.finalBalance).lt(0));
			this.disbursePaymentsCheck();
		}
	};

	cellFocused = (event: CellFocusedEvent) => {
		if ((event.column as Column).getColId() === 'paymentAmount') {
			// force focus on the currency input
			this.focusInvoicePaymentAmountId = this.invoicePaymentsGrid.api.getDisplayedRowAtIndex(event.rowIndex).data.invoiceId;
		}
	};

	updateAggregateRow() {
		this.paymentAmountSum = Number(GridUtil.sumCurrencyItems(this.invoicePayments, 'paymentAmount'));
		this.itemsTotalSum = Number(GridUtil.sumCurrencyItems(this.invoicePayments, 'itemsTotal'));
		this.transferTotalSum = Number(GridUtil.sumCurrencyItems(this.invoicePayments, 'transferTotal'));
		this.finalBalanceSum = Number(GridUtil.sumCurrencyItems(this.invoicePayments, 'finalBalance'));

		GridUtil.setAgGridAggregateRow(this.invoicePaymentsGrid, [{
			isAggregateRow: true,
			paymentAmount: this.paymentAmountSum,
			itemsTotal: this.itemsTotalSum,
			transferTotal: this.transferTotalSum,
			finalBalance: this.finalBalanceSum,
		}]);
	}

	isAggregateRow(rowData) {
		return !!rowData.isAggregateRow;
	}

	tabToNextCell = (params: TabToNextCellParams): CellPosition => {
		// focus on the previous/next row
		const index = params.previousCellPosition.rowIndex + (params.backwards ? -1 : 1);
		return {
			rowIndex: _clamp(index, 0, this.invoicePayments.length - 1),
			column: params.previousCellPosition.column,
			rowPinned: undefined,
		};
	};

	onTabKeydown(event: KeyboardEvent, isWriteOffDropdown: boolean) {
		// skip the event if bulk write-offs are on and this isn't the bulk write-off dropdown
		if (this.showBulkWriteOff() && !isWriteOffDropdown) {
			return;
		} else if (!this.invoicePaymentsGrid?.api.getDisplayedRowCount()) {
			return;
		}

		event.preventDefault();
		this.invoicePaymentsGrid.api.ensureIndexVisible(0);

		// sets focus into the first row payment column
		this.invoicePaymentsGrid.api.setFocusedCell(0, 'paymentAmount');
	}

	getInvoicePaymentById(invoiceId: number) {
		return this.paymentComponentService.getInvoicePaymentById(invoiceId);
	}

	getInvoicePaymentBalanceById(invoiceId: number) {
		return this.getInvoicePaymentById(invoiceId)?.finalBalance;
	}

	paymentTotalMatchesPaymentAmount() {
		return Big(this.formGroup.get('paymentAmount').value || 0).eq(this.paymentAmountSum || 0);
	}

	invoicePaymentItemsExceedAmount(invoicePayment: InvoicePayment) {
		return Big(invoicePayment?.itemsTotal || 0).gt(invoicePayment?.paymentAmount || 0);
	}

	allowManualSelectInvoice() {
		return EnumUtil.equalsOneOf(this.paymentGroup?.sourceType || this.payer?.sourceType, PaymentGroupSourceType.MANUAL, PaymentGroupSourceType.ERA);
	}

	maybeOpenSelectInvoiceModal() {
		// automatically open the modal the first time a new manual payment is viewed
		if (_isNil(this.paymentGroup) && this.allowManualSelectInvoice() && !this.hasState()) {
			this.openSelectInvoiceModal();
		}
	}

	openSelectInvoiceModal() {
		const subject = new Subject<InvoiceDashboardResponse[]>();
		this.modalManagerService.open(SelectInvoiceModalComponent, {
			data: {
				locationId: this.paymentGroup?.sourcePracticeLocationId || this.payer?.sourcePracticeLocationId,
				payer: {
					name: this.paymentGroup?.payerName || this.payer?.name,
					type: this.paymentComponentService.getPayerType().value,
				},
				outputSubject: subject,
				invoiceIds: this.invoicePayments.map(invoicePayment => invoicePayment.invoiceId),
			},
		}).onClose.subscribe(() => subject.complete());
		subject.pipe(takeUntil(this.unsubscribe)).subscribe(invoices => this.addManualInvoices(invoices));
	}

	addManualInvoices(invoices: InvoiceDashboardResponse[]) {
		const newInvoices = invoices.filter(invoice => !_some(this.invoicePayments, payment => payment.invoiceId === invoice.id));
		newInvoices.forEach(invoice => {
			const invoicePayment = this.receivePaymentsService.buildNewInvoicePayment(invoice, true);
			this.invoicePayments.push(invoicePayment);
			this.addPaymentForm(invoicePayment);
		});
		// We need to refresh the grid here because we are actually changing the set of invoicesPayments not just calculated values
		this.refreshGrid();
	}

	openTransferModal(invoicePayment: InvoicePayment) {
		this.receivePaymentsService.openTransferModal(this.modalManagerService, invoicePayment, this.bulkWriteOffs, false, this.paymentItemPermissions)
			.subscribe(transfersAndPayments => this.handleInvoicePaymentTransfersAndPayments(invoicePayment, transfersAndPayments));
	}

	handleInvoicePaymentTransfersAndPayments(invoicePayment: InvoicePayment, transfersAndPayments: ReceivePaymentsTransferItemsModalResponse) {
		if (_isNil(transfersAndPayments)) {
			return;
		}

		invoicePayment.transfersAndPayments = transfersAndPayments;
		this.updateInvoicePaymentRow(invoicePayment, invoicePayment.paymentAmount || transfersAndPayments.itemsPaidTotal);
	}

	updateInvoicePaymentRow(invoicePayment: InvoicePayment, amount: number) {
		this.ignoreInvoicePaymentAmountChange = true;
		this.getInvoicePaymentAmountControl(invoicePayment.invoiceId).setValue(amount);
		this.ignoreInvoicePaymentAmountChange = false;
		// Force update of row with new transfers
		this.onInvoicePaymentAmountChange(invoicePayment);
		const rowNode = this.invoicePaymentsGrid.api.getRowNode(invoicePayment.invoiceId.toString());
		this.invoicePaymentsGrid.api.redrawRows({rowNodes: [rowNode]});
	}

	hasState() {
		return !_isNil(this.state);
	}

	emitState() {
		this.paymentComponentService.changeReceivePaymentState({
			formState: {
				value: this.formGroup?.getRawValue(),
				submitted: this.templateForm?.submitted,
				mode: FormMode.UPDATE,
			},
			bulkWriteOffReasons: this.bulkWriteOffs,
			invoicePayments: this.invoicePayments as any,
			topDisplayedRowIndex: this.firstDisplayedRowIndex,
		});
	}

	/* istanbul ignore next */
	now() {
		return new Date();
	}

	get paymentGroup() {
		return this.paymentComponentService.paymentGroup;
	}

	get payer() {
		return this.paymentComponentService.payer;
	}

	get applyingPayment() {
		return this.paymentComponentService.applyingPayment;
	}

	get applyingPaymentWithoutProcessing() {
		return this.paymentComponentService.applyingPaymentWithoutProcessing;
	}

	get formGroup() {
		return this.paymentComponentService.formGroup;
	}

	get invoicePayments() {
		return this.paymentComponentService.invoicePayments;
	}
}
