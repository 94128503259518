import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalBase } from 'morgana';
import { QueryService } from '@core/query/query.service';
import { ImportMasterQueriesRequest } from '@gandalf/model/import-master-queries-request';
import { MasterQueryResponse } from '@gandalf/model/master-query-response';
import { QuerySummaryResponse } from '@gandalf/model/query-summary-response';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { CustomReportsMasterCategory, QueryTemplateCategory } from '@gandalf/constants';
import { GridUtil, RevColDef } from 'majora';
import { GandalfFormBuilder, TypedFormGroup } from 'gandalf';
import { AgGridAngular } from 'ag-grid-angular';

export interface ImportQueriesModalData {
	queryTemplateCategory: QueryTemplateCategory;
	customReportsMasterCategory: CustomReportsMasterCategory;
}

@Component({
	selector: 'pms-import-queries',
	templateUrl: './import-queries.component.html',
	styles: [],
})
export class ImportQueriesComponent extends ModalBase<ImportQueriesModalData, QuerySummaryResponse[]> implements OnInit {

	@ViewChild('dialog')
	dialog: DialogComponent;

	@ViewChild('agGrid')
	agGrid: AgGridAngular<MasterQueryResponse>;

	request: ImportMasterQueriesRequest;
	formGroup: TypedFormGroup<ImportMasterQueriesRequest>;
	masterQueries: MasterQueryResponse[];
	isSearching: boolean;

	gridColumns: RevColDef<MasterQueryResponse>[] = [
		GridUtil.buildCheckboxSelectionColumn(),
		GridUtil.buildColumn('Name', 'name', {sort: 'asc'}),
		GridUtil.buildFlexColumn('Description', 'description'),
	];

	constructor(
		private formBuilder: GandalfFormBuilder,
		private queryService: QueryService,
	) {
		super();
	}

	ngOnInit() {
		this.createForm();
		this.isSearching = true;
		this.queryService.findMasterQueriesByTemplateCategoryAndCustomReportsMasterCategory(
			this.data.queryTemplateCategory,
			this.data.customReportsMasterCategory,
		).subscribe(masterQueries => {
			this.isSearching = false;
			this.masterQueries = masterQueries;
		});
	}

	private createForm() {
		this.request = new ImportMasterQueriesRequest();
		this.request.masterQueryIds = [];
		this.formGroup = this.formBuilder.groupTyped(this.request);
	}

	updateSelectedQueries() {
		this.formGroup.controls.masterQueryIds.setValue(this.agGrid.api.getSelectedRows().map((query: MasterQueryResponse) => query.id));
	}

	importQueries() {
		if (this.formGroup.invalid) {
			return;
		}
		this.queryService.importMasterQueries(this.formGroup.value as ImportMasterQueriesRequest).subscribe(importedQueries => this.close(importedQueries));
	}

	close(importedQueries?: QuerySummaryResponse[]) {
		this.dynamicModalRef.close(this.dialog, importedQueries || []);
	}

}
