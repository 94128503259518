import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import _isNil from 'lodash/isNil';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { NgxExtendedPdfViewerComponent, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';

@Component({
	selector: 'pms-pdf-viewer-modal',
	templateUrl: './pdf-viewer-modal.component.html',
})
export class PdfViewerModalComponent implements AfterViewInit, OnDestroy {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('pdfViewer')
	pdfViewer: NgxExtendedPdfViewerComponent;

	title: string;
	fileName: string;
	openPrint: boolean;
	originalPrint = typeof window !== 'undefined' ? window.print : undefined;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		public ngxExtendedPdfViewerService: NgxExtendedPdfViewerService,
		public modalConfig: ModalConfig,
	) {
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.parseModalConfig(this.modalConfig.data);
		});
	}

	onLoad() {
		if (this.openPrint) {
			this.ngxExtendedPdfViewerService.print();
		}
	}

	ngOnDestroy(): Promise<void> {
		return this.pdfViewer.ngOnDestroy();
	}

	parseModalConfig(data: any) {
		this.title = data.title;
		if (!_isNil(data.fileUrl)) {
			this.pdfViewer.src = data.fileUrl;
		} else {
			this.pdfViewer.base64Src = data.base64Src;
		}
		this.fileName = data.fileName;
		this.openPrint = data.openPrint;
	}

	closeModal() {
		// Will be removed in https://revolutionehr.atlassian.net/browse/TD-1284
		document.getElementById('printContainer')?.remove();
		window.print = this.originalPrint;
		this.dynamicModalRef.close(this.modal);
	}

}
