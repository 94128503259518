// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { EncounterTableResponse } from './encounter-table-response';

// @ts-ignore
import { EyefinityOrderItemResponse } from './eyefinity-order-item-response';

// @ts-ignore
import { EyefinityOrderResponse } from './eyefinity-order-response';

// @ts-ignore
import { FrameColorResponse } from './frame-color-response';

// @ts-ignore
import { FrameSizeResponse } from './frame-size-response';

// @ts-ignore
import { LocationProductForOrderResponse } from './location-product-for-order-response';

// @ts-ignore
import { LocationProductItemForOrderResponse } from './location-product-item-for-order-response';

// @ts-ignore
import { OrderEyefinityFeeScheduleResponse } from './order-eyefinity-fee-schedule-response';

// @ts-ignore
import { OrderProcessorConfigResponse } from './order-processor-config-response';

// @ts-ignore
import { OrderVendorResponse } from './order-vendor-response';

// @ts-ignore
import { PatientCommunicationResponse } from './patient-communication-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.order.eyefinity.EyefinityFrameOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class EyefinityFrameOrderResponse extends EyefinityOrderResponse {

	@GandalfProperty({ designType: String })
	associatedAuthorization!: string | null;

	@GandalfProperty({ designType: Boolean })
	benefitContactLens!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	benefitExam!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	benefitFrame!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	benefitLens!: boolean | null;

	@GandalfProperty({ designType: String })
	comment!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	copayExam!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	copayMaterials!: number | null;

	@GandalfProperty({ designType: Date })
	createdOn!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dueDate!: Date | null;

	@GandalfProperty({ designType: EncounterTableResponse })
	encounter!: EncounterTableResponse | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	estimatedDiscounts!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	estimatedInsuranceBenefit!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	estimatedTaxes!: number | null;

	@GandalfProperty({ designType: String })
	externalSupplierIdentifier!: string | null;

	@GandalfProperty({ designType: String })
	externalSupplierName!: string | null;

	@GandalfProperty({ designType: OrderEyefinityFeeScheduleResponse })
	eyefinityFeeSchedule!: OrderEyefinityFeeScheduleResponse | null;

	@GandalfProperty({ designType: Number })
	eyefinityOrderDetailId!: number | null;

	@GandalfArray(EyefinityOrderItemResponse)
	eyefinityOrderItems!: EyefinityOrderItemResponse[] | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	frameAllowance!: number | null;

	@GandalfProperty({ designType: FrameColorResponse })
	frameColor!: FrameColorResponse | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	frameCost!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	frameRemakeCost!: number | null;

	@GandalfProperty({ designType: FrameSizeResponse })
	frameSize!: FrameSizeResponse | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	frameWholesaleAllowance!: number | null;

	@GandalfProperty({ designType: String })
	instructions!: string | null;

	@GandalfProperty({ designType: Boolean })
	isValid!: boolean | null;

	@GandalfProperty({ designType: String })
	legacyAuthorizationIdentifier!: string | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ designType: String })
	locationName!: string | null;

	@GandalfProperty({ designType: String })
	notificationComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	notificationDate!: Date | null;

	@GandalfProperty({ designType: Boolean })
	onHold!: boolean | null;

	@GandalfProperty({ designType: LocationProductForOrderResponse })
	orderFrameProduct!: LocationProductForOrderResponse | null;

	@GandalfProperty({ designType: LocationProductItemForOrderResponse })
	orderFrameProductItem!: LocationProductItemForOrderResponse | null;

	@GandalfConstantDecorator(constants.OrderFrameSource)
	@GandalfProperty({ designType: constants.OrderFrameSource })
	orderFrameSource!: constants.OrderFrameSource | null;

	@GandalfProperty({ designType: Number })
	orderId!: number | null;

	@GandalfProperty({ designType: Number })
	originalOrderId!: number | null;

	@GandalfConstantDecorator(constants.OriginalOrderReason)
	@GandalfProperty({ designType: constants.OriginalOrderReason })
	originalOrderReason!: constants.OriginalOrderReason | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	otherCost!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	otherRemakeCost!: number | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patient!: PatientNameResponse | null;

	@GandalfProperty({ designType: PatientCommunicationResponse })
	patientCommunication!: PatientCommunicationResponse | null;

	@GandalfProperty({ designType: Boolean })
	patientNotified!: boolean | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfProperty({ designType: OrderProcessorConfigResponse })
	processor!: OrderProcessorConfigResponse | null;

	@GandalfProperty({ designType: Number })
	quantity!: number | null;

	@GandalfProperty({ designType: AddressResponse })
	shipAddress!: AddressResponse | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	shipCost!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	shipRemakeCost!: number | null;

	@GandalfConstantDecorator(constants.OrderShipToType)
	@GandalfProperty({ designType: constants.OrderShipToType })
	shipToType!: constants.OrderShipToType | null;

	@GandalfConstantDecorator(constants.OrderShipmentCompany)
	@GandalfProperty({ designType: constants.OrderShipmentCompany })
	shippingCompany!: constants.OrderShipmentCompany | null;

	@GandalfConstantDecorator(constants.OrderShippingSpeed)
	@GandalfProperty({ designType: constants.OrderShippingSpeed })
	shippingSpeed!: constants.OrderShippingSpeed | null;

	@GandalfProperty({ designType: Number })
	smartflowOrderIdentifier!: number | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty({ designType: constants.OrderStatusCode })
	statusCode!: constants.OrderStatusCode | null;

	@GandalfProperty({ designType: Date })
	statusDate!: Date | null;

	@GandalfProperty({ designType: String })
	trackingNumber!: string | null;

	@GandalfProperty({ designType: String })
	tray!: string | null;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty({ designType: constants.OrderType })
	type!: constants.OrderType | null;

	@GandalfProperty({ designType: String })
	validationMessage!: string | null;

	@GandalfProperty({ designType: OrderVendorResponse })
	vendor!: OrderVendorResponse | null;

	@GandalfProperty({ designType: String })
	vendorOrderIdentifier!: string | null;

	@GandalfProperty({ designType: Number })
	version!: number | null;

}
