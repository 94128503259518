<div ngbDropdown>
	<button
		[disabled]="disabled"
		ngbDropdownToggle
		type="button"
		class="form-select"
		[class.maj-dropdown-select-with-value]="selectedItem"
		[class.ng-invalid]="control.invalid">
		<span>{{ getDropdownText() }}</span>
		@if (selectedItem && allowClear && !disabled) {
			<i (click)="clearSelection($event)" class="fa fa-times ms-3 me-1 px-1"></i>
		}
	</button>
	<div ngbDropdownMenu>
		@for (item of items; track item) {
			<button type="button" ngbDropdownItem (click)="handleItemChange(item)">{{ item.label }}</button>
		}
	</div>
</div>
