import { booleanAttribute, Component, EventEmitter, Input, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { _isNil } from 'majora/lodash';
import { OptionItem } from '../../models/option-item';

@Component({
	selector: 'maj-select-dropdown',
	standalone: true,
	imports: [FormsModule, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem],
	templateUrl: './select-dropdown.component.html',
	styleUrls: ['./select-dropdown.component.scss'],
	providers: [],
})
export class SelectDropdownComponent<T extends OptionItem<K>, K> implements ControlValueAccessor {

	@Input({required: true})
	items: T[] = [];

	/**
	 * If emitFullOption is set to true, the valueChanged event will emit the full OptionItem object instead of just the value.
	 * Likewise, the ngModel will be set to the full OptionItem object instead of just the value. This is particularly useful
	 * when working with forms and requests that expect a full "GandalfConstant" object instead of just a primitive value.
	 * When writing a value to the component when this option is set to "true", the value should be an OptionItem object.
	 * @default false
	 */
	@Input({transform: booleanAttribute})
	emitFullOption = false;

	@Input({required: false})
	placeHolder: string = '';

	@Input({required: false})
	allowClear = true;

	@Output()
	valueChanged = new EventEmitter<T | K | null>();

	touched = false;

	selectedItem: T | null = null;

	protected disabled = false;

	constructor(@Self() protected control: NgControl) {
		this.control.valueAccessor = this;
	}

	/* istanbul ignore next */
	onTouched = () => {};

	/* istanbul ignore next */
	onModelChange = (_newValue: T | K | null) => {};

	writeValue(newValue: T | K): void {
		if (_isNil(newValue)) {
			this.selectedItem = null;
			return;
		}

		if (this.emitFullOption) {
			if (typeof newValue != 'object') {
				console.error('SelectDropdownComponent: emitFullOption is set to true, but the written value is not an object');
			} else {
				this.selectedItem = newValue as T;
			}
		} else {
			this.selectedItem = this.items.find(item => item.value === newValue) || null;
		}
	}

	registerOnChange(fn: any): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	handleItemChange(selectedItem: T | null) {
		this.selectedItem = selectedItem;
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}

		if (this.emitFullOption) {
			this.onModelChange(this.selectedItem);
			this.valueChanged.emit(this.selectedItem);
		} else {
			const value = _isNil(this.selectedItem) ? null : this.selectedItem!.value;
			this.onModelChange(value);
			this.valueChanged.emit(value);
		}
	}

	getDropdownText(): string {
		if (this.selectedItem) {
			return this.selectedItem.label;
		} else {
			return this.placeHolder;
		}
	}

	clearSelection(event: Event) {
		event.stopPropagation();
		this.handleItemChange(null);
	}

}
