import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CategoryService } from '@core/category/category.service';
import { _find } from '@core/lodash/lodash';
import { CategoryEntityType } from '@gandalf/constants';
import { CategoryResponse } from '@gandalf/model/category-response';
import { GandalfConstant } from 'gandalf';
import { AgGridAngular } from 'ag-grid-angular';
import _isNil from 'lodash/isNil';
import { GridUtil, RevColDef } from 'majora';

@Component({
	selector: 'pms-category-list',
	templateUrl: './category-list.component.html',
	styles: [],
})
export class CategoryListComponent implements OnInit {

	@ViewChild('agGrid')
	agGrid: AgGridAngular<CategoryResponse>;

	@Input()
	entityType: CategoryEntityType;

	@Output()
	openEvent = new EventEmitter<CategoryResponse>();

	categories: CategoryResponse[] = [];
	isSearching = false;

	statusFilterOptions = [
		new GandalfConstant(true, 'Active'),
		new GandalfConstant(false, 'Inactive'),
	];
	statusFilter = this.statusFilterOptions[0];

	gridColumns: RevColDef<CategoryResponse>[] = [
		GridUtil.buildColumn('Name', 'name', { width: 250, rowDrag: true, sortable: false }),
		GridUtil.buildFlexColumn('Description', 'description', { sortable: false }),
		GridUtil.buildBooleanColumn('Editable', 'editable', { width: 100, sortable: false }),
		GridUtil.buildBooleanColumn('Status', 'active', { width: 100, sortable: false }, 'Active', 'Inactive'),
	];

	constructor(
		public categoryService: CategoryService,
	) {
	}

	ngOnInit() {
		this.getCategoriesList();
	}

	getCategoriesList(): void {
		this.isSearching = true;
		this.categoryService.findByEntityTypeForTable(this.entityType).subscribe((data) => {
			this.categories = data;
			this.filterTable();
			this.isSearching = false;
		});
	}

	openCategoryDetails(row: any): void {
		this.openEvent.emit(_find(this.categories, category => category.id === row.id));
	}

	reorderCategories() {
		this.agGrid.api.forEachNode((node, index) => {
			node.data.sortOrder = index;
		});
		this.categoryService.updateCategorySortOrder(this.categories).subscribe((data) => {
			this.categories = data;
		});
	}

	filterTable() {
		GridUtil.applyFilter(this.agGrid, () => !_isNil(this.statusFilter?.value), 'active', this.statusFilter);
	}
}
