import { _orderBy } from 'majora/lodash';

type SortValueAccessor<T> = (item: T) => string | number | Date | null;
type SortOrder = 'asc' | 'desc';

export class SortingUtil {

	/**
	 * Sorts a collection based on 1 or more value-accessor functions. String values will be sorted as case-insensitive
	 * @param collection The collection to sort
	 * @param valueAccessors An array of functions for accessing each value to sort by
	 * @param sortOrder The sort order for each value accessor function. Will default to 'asc' if not specified
	 */
	static sortBy<T>(collection: T[], valueAccessors: SortValueAccessor<T>[], sortOrder?: SortOrder[]): T[] {
		const caseInsensitiveAccessors = valueAccessors.map(valueAccessor => {
			return (collectionItem: T) => {
				const value = valueAccessor(collectionItem);
				if (typeof value === 'string') {
					return value.toLowerCase();
				}
				return value;
			};
		});
		return _orderBy(collection, caseInsensitiveAccessors, sortOrder);
	}

}
