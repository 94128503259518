<div ngbDropdown (openChange)="onOpenChange($event)">
	<button
		ngbDropdownToggle
		[class.maj-multi-select-with-value]="hasSelection()"
		type="button"
		class="form-select"
		[class.ng-invalid]="control.invalid">
		<span>{{ getDropdownText() }}</span>
		@if (hasSelection() && !disabled) {
			<i (click)="clearSelection($event)" class="fa fa-times ms-3 me-1 px-1"></i>
		}
	</button>
	<div ngbDropdownMenu>
		<ul class="list-group list-group-flush">
			<li class="list-group-item">
				<input #filterInput (blur)="focusFilterInput()" [(ngModel)]="filterText" (input)="onFilterTextChange()" class="form-control"
					   placeholder="Search" type="text"/>
			</li>
			<li class="list-group-item">
				<input
					(change)="onSelectAllChange()"
					[(ngModel)]="selectAllValue"
					[disabled]="disabled"
					class="form-check-input me-1"
					type="checkbox"
					value=""
					id="selectAllCheckbox">
				<label class="form-check-label stretched-link" for="selectAllCheckbox">Select All</label>
			</li>
			<div class="maj-list-group-scrollable list-group-flush">
				@for (item of displayedItems; track item) {
					<li class="list-group-item">
						<input
							#checkboxItems
							(change)="onItemChange(item, checkboxItems)"
							[disabled]="disabled"
							[checked]="isItemChecked(item)"
							class="form-check-input me-1"
							type="checkbox"
							value=""
							[id]="'checkbox-' + item.value">
						<label class="form-check-label stretched-link" [for]="'checkbox-' + item.value">{{ item.label }}</label>
					</li>
				}
			</div>
		</ul>
	</div>
</div>
