import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: 'input[majCurrencyInput]',
	standalone: true,
})
export class CurrencyInputDirective implements OnInit {

	constructor(
		private elementRef: ElementRef<HTMLInputElement>,
		private ngControl: NgControl,
		private renderer: Renderer2,
	) {
	}

	ngOnInit(): void {
		// Only allow numeric values
		this.renderer.setAttribute(this.elementRef.nativeElement, 'type', 'number');
		this.renderer.setAttribute(this.elementRef.nativeElement, 'step', '0.01');
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		// Prevent 'e' scientific notation
		if (event.key === 'e' || event.key === 'E') {
			event.preventDefault();
			return;
		}

		// Force format logic to run on Enter key to ensure a form submission will format the value
		if (event.key === 'Enter') {
			this.formatValue();
		}
	}

	@HostListener('blur')
	onBlur() {
		this.formatValue();
	}

	private formatValue() {
		const value = this.elementRef.nativeElement.value;
		if (value) {
			const formattedValue = parseFloat(value).toFixed(2);
			this.elementRef.nativeElement.value = formattedValue;
			this.ngControl.control?.setValue(Number(formattedValue));
		} else {
			this.ngControl.control?.setValue(null);
		}
	}

}
