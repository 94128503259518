import { booleanAttribute, Component, ElementRef, EventEmitter, Input, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { _isEmpty, _isNil } from 'majora/lodash';
import { OptionItem } from '../../models/option-item';

@Component({
	selector: 'maj-multi-select-dropdown',
	standalone: true,
	imports: [FormsModule, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu],
	templateUrl: './multi-select-dropdown.component.html',
	styleUrls: ['./multi-select-dropdown.component.scss'],
	providers: [],
})
export class MultiSelectDropdownComponent<T extends OptionItem<K>, K> implements ControlValueAccessor {

	@ViewChild('filterInput')
	filterInput!: ElementRef<HTMLInputElement>;

	private _items: T[] = [];

	@Input({required: true})
	set items(items: T[]) {
		if (_isNil(items)) {
			this._items = [];
		} else {
			this._items = items;
		}
		this.displayedItems = items;
	}

	/**
	 * If emitFullOption is set to true, the valueChanged event will emit the full OptionItem object instead of just the value.
	 * Likewise, the ngModel will be set to the full OptionItem object instead of just the value. This is particularly useful
	 * when working with forms and requests that expect a full "GandalfConstant" object instead of just a primitive value.
	 * When writing a value to the component when this option is set to "true", the value should be an OptionItem object.
	 * @default false
	 */
	@Input({transform: booleanAttribute})
	emitFullOption = false;

	@Input({required: true})
	placeHolder: string = '';

	@Output()
	valueChanged = new EventEmitter<(T | K)[]>();

	displayedItems: T[] = [];

	filterText: string = '';

	selectAllValue: boolean = false;

	touched = false;

	selectedValues: T[] = [];

	protected disabled = false;

	constructor(@Self() protected control: NgControl) {
		control.valueAccessor = this;
	}

	/* istanbul ignore next */
	onTouched = () => {
	};

	/* istanbul ignore next */
	onModelChange = (_newValue: (T | K)[]) => {
	};

	writeValue(newValue: (T | K)[]): void {
		if (_isNil(newValue)) {
			this.selectedValues = [];
			return;
		}
		if (this.emitFullOption) {
			this.selectedValues = newValue as T[];
		} else {
			this.selectedValues = this._items.filter(item => newValue.includes(item.value));
		}
	}

	registerOnChange(fn: any): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	handleItemChange() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}

		if (this.emitFullOption) {
			this.onModelChange(this.selectedValues);
			this.valueChanged.emit(this.selectedValues);
		} else {
			const values = this.selectedValues.map(selectedValue => selectedValue.value);
			this.onModelChange(values);
			this.valueChanged.emit(values);
		}
	}

	focusFilterInput() {
		this.filterInput.nativeElement.focus();
	}

	onOpenChange(opened: boolean) {
		if (opened) {
			// Allow a tick for the pop-up to display before focusing on the search input
			setTimeout(() => {
				this.focusFilterInput();
			});
		}
	}

	onSelectAllChange() {
		if (this.selectAllValue) {
			this.selectedValues = this.displayedItems;
		} else {
			this.selectedValues = [];
		}
		this.handleItemChange();
	}

	onItemChange(changedItem: T, inputEl: HTMLInputElement) {
		if (inputEl.checked) {
			this.selectedValues.push(changedItem);
		} else {
			const indexToRemove = this.selectedValues.findIndex((item) => item === changedItem);
			this.selectedValues.splice(indexToRemove, 1);
		}
		this.handleItemChange();
	}

	isItemChecked(itemToCheck: T): boolean {
		return this.selectedValues.some(selectedValue => selectedValue.value === itemToCheck.value);
	}

	getDropdownText() {
		if (_isEmpty(this.selectedValues)) {
			return this.placeHolder;
		} else {
			return `${this.selectedValues.length} selected`;
		}
	}

	onFilterTextChange() {
		const trimmed = this.filterText.trim();
		if (_isEmpty(trimmed)) {
			this.displayedItems = this._items;
			return;
		}
		this.displayedItems = this._items.filter(item => item.label.toLowerCase().indexOf(trimmed.toLowerCase()) !== -1);
	}

	clearSelection(event: Event) {
		event.stopPropagation();
		this.selectedValues = [];
		this.selectAllValue = false;
		this.handleItemChange();
	}

	hasSelection() {
		return this.selectedValues?.length > 0;
	}

}
