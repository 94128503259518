import { Injectable } from '@angular/core';
import { _filter, _isNil } from '@core/lodash/lodash';
import { SortingService } from 'morgana';
import { CarePlanItemHistoryResponse } from '@gandalf/model/care-plan-item-history-response';
import { EncounterAddCarePlanItemsToPersonDiagnosisRequest } from '@gandalf/model/encounter-add-care-plan-items-to-person-diagnosis-request';
import { CarePlanItemGandalfService, PlanGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface FilterableCarePlanItemHistoryResponse extends CarePlanItemHistoryResponse {
	active: boolean;
}

@Injectable({
	providedIn: 'root',
})

export class CarePlanItemService {

	constructor(
		private carePlanItemGandalfService: CarePlanItemGandalfService,
		private planGandalfService: PlanGandalfService,
	) { }

	/* istanbul ignore next: gandalf */
	findCarePlanItemsByPersonDiagnosisId(personDiagnosisId: number): Observable<FilterableCarePlanItemHistoryResponse[]> {
		return this.carePlanItemGandalfService.findCarePlanItemsByPersonDiagnosisId(personDiagnosisId).pipe(
			map((carePlanItems: FilterableCarePlanItemHistoryResponse[]) => carePlanItems.map(
				carePlanHistoryItem => this.buildFilterableCarePlanItemHistoryResponse(carePlanHistoryItem))),
			map(carePlanItems => this.sortCarePlanItems(carePlanItems)),
		);
	}

	findCarePlanItemsForEncounterByPersonDiagnosisId(personDiagnosisId: number, encounterId: number): Observable<FilterableCarePlanItemHistoryResponse[]> {
		return this.carePlanItemGandalfService.findCarePlanItemsByPersonDiagnosisId(personDiagnosisId).pipe(
			map(carePlanItems => this.filterCarePlanItemsNotInEncounter(carePlanItems, encounterId)),
			map((carePlanItems: FilterableCarePlanItemHistoryResponse[]) => carePlanItems.map(
				carePlanHistoryItem => this.buildFilterableCarePlanItemHistoryResponse(carePlanHistoryItem))),
			map(carePlanItems => this.sortCarePlanItems(carePlanItems)),
		);
	}

	/**
	 * Keeps the CarePlanItemHistoryResponses that either do not have the same encounterId
	 * as the one provided, or if its originalDiagnosisId is null.
	 */
	filterCarePlanItemsNotInEncounter(items: CarePlanItemHistoryResponse[], encounterId: number) {
		return _filter(items, item => item.carePlanItem?.encounterId !== encounterId || _isNil(item.originalPersonDiagnosisId));
	}

	sortCarePlanItems(carePlanItems: FilterableCarePlanItemHistoryResponse[]) {
		return SortingService.sortBy(
			carePlanItems,
			[
				carePlanItemHistory => carePlanItemHistory.carePlanItem.startDate,
				carePlanItemHistory => carePlanItemHistory.carePlanItem.id,
			],
			[
				'desc',
				'desc',
			],
		);
	}

	/* istanbul ignore next: gandalf */
	addHistoricCarePlanItemsToPersonDiagnosis(request: EncounterAddCarePlanItemsToPersonDiagnosisRequest): Observable<void> {
		return this.planGandalfService.addHistoricCarePlanItemsToPersonDiagnosis(request);
	}

	buildFilterableCarePlanItemHistoryResponse(carePlanHistoryItem: FilterableCarePlanItemHistoryResponse): FilterableCarePlanItemHistoryResponse {
		carePlanHistoryItem.active = _isNil(carePlanHistoryItem.carePlanItem.stopDate);
		return carePlanHistoryItem;
	}
}
