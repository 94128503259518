import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PerformanceService } from '@core/performance/performance.service';
import { registerLicense } from '@syncfusion/ej2-base';
import { GridUtil } from 'majora';
import { SYNCFUSION_LICENSE } from 'morgana';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Syncfusion license registration
registerLicense(SYNCFUSION_LICENSE);
// suppress the default ag-grid pagination panel since we use AgGridPaginationComponent instead
GridUtil.initGlobalGridOptions({
	suppressPaginationPanel: true,
});

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
	.then(ref => {
		// Enable the use of ng.profiler in the console
		const applicationRef = ref.injector.get(ApplicationRef);
		const componentRef = applicationRef.components[0];
		enableDebugTools(componentRef);
		// Enable access to performanceService on the window object for use in the console
		window['performanceService'] = ref.injector.get(PerformanceService);
	})
	.catch(err => console.error(err));
