import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class ValidatorUtils {
	static addErrorToControl(control: AbstractControl, message: string, errorProperty: string) {
		const errors = control.errors || {};
		errors[errorProperty] = message;
		control.setErrors(errors);
	}

	static removeErrorFromControl(control: AbstractControl, errorProperty: string) {
		const errors = control.errors;
		if (errors) {
			delete errors[errorProperty];
		}
		if (errors && Object.keys(errors).length === 0) {
			control.setErrors(null);
		}
	}

	static removeErrorPropertyFromControls(controlNames: any[], group: UntypedFormGroup, errorProperty: string) {
		controlNames.forEach(control => ValidatorUtils.removeErrorFromControl(group.controls[control], errorProperty));
	}

	static addErrorPropertyToControls(controlNames: any[], group: UntypedFormGroup, message: string, errorProperty: string) {
		controlNames.forEach(control => ValidatorUtils.addErrorToControl(group.controls[control], message, errorProperty));
	}

	static deleteErrorPropertyFromGroup(group: UntypedFormGroup, errorProperty: string) {
		let groupErrors = group.errors;
		if (groupErrors !== null && groupErrors !== undefined) {
			delete (groupErrors[errorProperty]);
			if (Object.keys(groupErrors).length === 0) {
				groupErrors = null;
			}
		}
		return groupErrors;
	}
}
