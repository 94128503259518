import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { EnsureHttpPipe, ModalBase } from 'morgana';
import { UrlService } from '@core/url-util/url.service';
import { FeatureFlagAccess } from '@gandalf/constants';
import { FeatureFlagDetailResponse } from '@gandalf/model/feature-flag-detail-response';
import { URL_BASE_PATHS } from '@shared/constants/url.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GridUtil, OptionItem, RevColDef } from 'majora';
import { AgGridAngular } from 'ag-grid-angular';
import { RowClickedEvent } from 'ag-grid-community';
import { ManageUpdatesService } from '../services/manage-updates.service';

@Component({
	selector: 'pms-manage-updates-modal',
	templateUrl: './manage-updates-modal.component.html',
	styles: [],
})
export class ManageUpdatesModalComponent extends ModalBase implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('agGrid')
	agGrid: AgGridAngular<FeatureFlagDetailResponse>;

	@ViewChild('switchColumn')
	switchColumn: TemplateRef<any>;

	featureFlags: FeatureFlagDetailResponse[] = [];
	selectedFeatureFlag: FeatureFlagDetailResponse;

	statusOptions: OptionItem<boolean>[] = [
		{label: 'All', value: null},
		{label: 'On', value: true},
		{label: 'Off', value: false},
	];
	selectedStatus = false;

	gridColumns: RevColDef<FeatureFlagDetailResponse>[] = [
		GridUtil.buildColumn('', 'internalName', {flex: 1}),
		GridUtil.buildFieldTemplateColumn('', 'enabled', () => this.switchColumn, {
			width: 100,
			cellDataType: 'text',
			filter: true,
		}),
	];
	isSearching = false;

	constructor(
		public urlService: UrlService,
		public ensureHttpPipe: EnsureHttpPipe,
		public manageUpdatesService: ManageUpdatesService,
		public authenticationService: AuthenticationService,
	) {
		super();
	}

	ngOnInit() {
		this.loadFeatureFlags();
	}

	loadFeatureFlags() {
		this.isSearching = true;

		this.manageUpdatesService.getFeatureFlags().subscribe(data => {
			this.featureFlags = data;
			this.isSearching = false;
			this.filterGrid();
		});
	}

	filterGrid() {
		GridUtil.applyFilter(this.agGrid, () => this.selectedStatus !== null, 'enabled', this.selectedStatus?.toString());
		this.agGrid.api.paginationGoToFirstPage();
		this.deselectFeatureFlag();
	}

	selectFeatureFlag(event: RowClickedEvent<FeatureFlagDetailResponse>) {
		this.selectedFeatureFlag = event.data;
	}

	deselectFeatureFlag() {
		this.selectedFeatureFlag = null;
		this.agGrid.api.deselectAll();
	}

	closeModal() {
		this.dynamicModalRef.close(this.modal);
	}

	getImageUrl(): string {
		if (this.selectedFeatureFlag && this.selectedFeatureFlag.imageFileId) {
			return `${URL_BASE_PATHS.PMS2}/featureFlag/serveFile?systemFileId=${this.selectedFeatureFlag.imageFileId}`;
		}
		return '';
	}

	handleSwitchChange(event: any, featureFlagCopy: FeatureFlagDetailResponse) {
		const newFeatureFlagAccess = (event.checked ? FeatureFlagAccess.ON : FeatureFlagAccess.OFF);

		const updatedEmployeeFlag = (featureFlagCopy.employeeFlag ?
			this.manageUpdatesService.updateFeatureFlagEmployeeAccess(featureFlagCopy.employeeFlag.id, newFeatureFlagAccess)
			: this.manageUpdatesService.createFeatureFlagEmployee(featureFlagCopy.id, newFeatureFlagAccess));
		updatedEmployeeFlag.subscribe();
	}

	openDocumentationLink(link: string) {
		if (link) {
			this.urlService.goToWebpage(this.ensureHttpPipe.transform(link), '_blank');
		}
	}
}
