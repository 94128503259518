<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'manageUpdatesModal'">
	<ng-template #header>
		<div class="dlg-template">Manage Updates</div>
	</ng-template>
	<ng-template #content>
		<div class="row">
			<div class="col-sm-5">
				<p><b>We are making changes!</b></p>
				<p>We have given you the ability to control access and provide early feedback on our enhancements.</p>
				<p>You can enable or disable updated sections as you desire. Changes will be applied and visible the next time you login.</p>
				<rev-ag-grid-container [agGrid]="agGrid" [items]="[]">
					<ng-container rev-table-action-menu-left>
						<div class="e-toolbar-item">
							<label>Status</label>
							<rev-status-select-button
								[options]="statusOptions"
								[(ngModel)]="selectedStatus"
								[ngModelOptions]="{standalone: true}"
								(onChange)="filterGrid()">
							</rev-status-select-button>
						</div>
					</ng-container>
					<ng-container rev-container-grid>
						<ag-grid-angular
							#agGrid
							class="ag-theme-alpine"
							rowClass="row-link"
							headerHeight="0"
							rowSelection="single"
							pmsAgAutoFitColumns
							[attr.data-test-id]="'manageUpdatesModalGrid'"
							[rowData]="featureFlags"
							[columnDefs]="gridColumns"
							[loading]="isSearching"
							(rowClicked)="selectFeatureFlag($event)">
						</ag-grid-angular>
					</ng-container>
				</rev-ag-grid-container>
			</div>
			<div class="col-sm-7">
				<div class="row text-center" [attr.data-test-id]="'manageUpdatesModalPreviePanel'">
					@if (selectedFeatureFlag) {
						<div class="col-sm-12">
							@if (selectedFeatureFlag?.imageFileId) {
								<div>
									<img [src]="getImageUrl()">
								</div>
							}
							<h2>{{ selectedFeatureFlag?.title }}</h2>
							<span [innerHTML]="selectedFeatureFlag?.description"></span>
							@if (selectedFeatureFlag?.documentationLink) {
								<span>
									<a (click)="openDocumentationLink(selectedFeatureFlag?.documentationLink)">Learn More...</a>
								</span>
							}
						</div>
					}
					@if (!selectedFeatureFlag && featureFlags?.length) {
						<div class="col-sm-12">
							<i class="fa fa-image" style="font-size: 16em"></i>
							<p>Select a feature on the left to preview</p>
						</div>
					}
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close" [attr.data-test-id]="'manageUpdatesModalCloseButton'">Close</button>
	</ng-template>
</ejs-dialog>

<ng-template #switchColumn let-data>
	<ejs-switch
		[(ngModel)]="data.enabled"
		[ngModelOptions]="{standalone: true}"
		(change)="handleSwitchChange($event, data)">
	</ejs-switch>
</ng-template>
