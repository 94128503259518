import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { formatCurrency, formatDate } from '@angular/common';
import { _isNil } from 'majora/lodash';
import { ColDefField } from 'ag-grid-community';
import { GridUtil, RevColDef } from './grid-util';

@Injectable({
	providedIn: 'root',
})
export class GridUtilService {

	readonly locale = inject(LOCALE_ID);

	buildDateColumn<TData>(headerName: string, field: ColDefField<TData>, overrides?: RevColDef<TData>, dateFormat: string = 'MM/dd/yyyy'): RevColDef<TData> {
		return GridUtil.applyOverrides({
			headerName,
			field,
			valueFormatter: (params) => _isNil(params.value) ? '' : formatDate(params.value, dateFormat, this.locale),
		}, overrides);
	}

	buildCurrencyColumn<TData>(headerName: string, field: ColDefField<TData>, overrides?: RevColDef<TData>): RevColDef<TData> {
		return GridUtil.applyOverrides({
			headerName,
			field,
			valueFormatter: (params) => _isNil(params.value) ? '' : formatCurrency(params.value, this.locale, '$'),
		}, overrides);
	}

}
