import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { inject } from '@angular/core';

export abstract class ModalBase<T = null, R = void> {
	protected activeModal = inject(NgbActiveModal);

	/**
	 * Data passed to the modal component.
	 * Will not be available until `ngOnInit` is called.
	 */
	data!: T;

	close(result?: R) {
		this.activeModal.close(result);
	}

}
