import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { _find } from '@core/lodash/lodash';
import { ReferenceDataResponseForTable } from '@core/reference-data/reference-data.service';
import { ReferenceDataMasterCategory } from '@gandalf/constants';
import { ReferenceDataResponse } from '@gandalf/model/reference-data-response';
import {AgGridAngular} from 'ag-grid-angular';
import { GandalfConstant } from 'gandalf';
import _isNil from 'lodash/isNil';
import { GridUtil, RevColDef } from 'majora';
import { AdminReferenceDataService } from '../admin-reference-data.service';

@Component({
	selector: 'pms-reference-data-list',
	templateUrl: './reference-data-list.component.html',
	styles: [],
})
export class ReferenceDataListComponent implements OnInit{

	@Input()
	category: ReferenceDataMasterCategory;

	@Output()
	openEvent = new EventEmitter<ReferenceDataResponse>();

	@ViewChild('agGrid')
	agGrid: AgGridAngular<ReferenceDataResponse>;

	referenceDataList: ReferenceDataResponse[] = [];
	isSearching = false;

	statusFilterOptions = [
		new GandalfConstant(true, 'Active'),
		new GandalfConstant(false, 'Inactive'),
	];
	statusFilter = this.statusFilterOptions[0];

	gridColumns: RevColDef<ReferenceDataResponse>[] = [
		GridUtil.buildColumn('Name', 'value', { width: 300, rowDrag: true, sortable: false }),
		GridUtil.buildFlexColumn('Description (Internal Use Only)', 'description', { width: 300, sortable: false }),
		GridUtil.buildBooleanColumn('Editable', 'editable', { width: 100, sortable: false }),
		GridUtil.buildBooleanColumn('Status', 'active', { width: 100, sortable: false }, 'Active', 'Inactive'),
	];

	constructor(
		protected referenceDataService: AdminReferenceDataService,
	) {
	}

	ngOnInit() {
		this.getReferenceDataList();
	}

	getReferenceDataList(): void {
		this.isSearching = true;
		this.referenceDataService.getReferenceDataByCategoryIdForTable(this.category.value).subscribe((data) => {
			this.referenceDataList = data;
			this.filterTable();
			this.isSearching = false;
		});
	}

	openReferenceDataDetails(row: any): void {
		this.openEvent.emit(_find(this.referenceDataList, referenceData => referenceData.id === row.id));
	}

	filterTable() {
		GridUtil.applyFilter(this.agGrid, () => !_isNil(this.statusFilter?.value), 'active', this.statusFilter);
	}

	reorderReferenceData() {
		this.agGrid.api.forEachNode((node, index) => {
			node.data.sortOrder = index;
		});
		this.referenceDataService.updateReferenceDataSortOrder(this.referenceDataList as ReferenceDataResponseForTable[]).subscribe((data) => {
			this.referenceDataList = data;
		});
	}
}
