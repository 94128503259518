import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { _isNil } from 'majora/lodash';

@Component({
	standalone: true,
	selector: 'maj-conditional-hyperlink-cell-renderer',
	templateUrl: './conditional-hyperlink-cell-renderer.component.html',
	imports: [],
})
export class ConditionalHyperlinkCellRendererComponent<TData> implements ICellRendererAngularComp {
	showAsLink!: (rowData: TData) => boolean;
	onLinkClicked!: (rowData: TData) => void;
	data!: TData;
	value = null;
	hasValue = false;

	agInit(params: ICellRendererParams<TData> & { showAsLink: (rowData: TData) => boolean, onLinkClicked: (rowData: TData) => void }): void {
		this.showAsLink = params.showAsLink ?? (() => true);
		this.onLinkClicked = params.onLinkClicked ?? (() => {});
		this.refresh(params);
	}

	refresh(params: ICellRendererParams<TData>): boolean {
		this.data = params.data as TData;
		this.value = params.valueFormatted ?? params.value;
		this.hasValue = !_isNil(this.value);
		return true;
	}

	linkClicked(event: MouseEvent) {
		event.stopPropagation();
		this.onLinkClicked(this.data);
	}
}
