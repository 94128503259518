<div class="btn-group btn-group" role="group">
	@for (item of items; track item) {
		<input
			[(ngModel)]="selectedOption"
			(change)="handleItemChange()"
			[value]="item"
			[disabled]="disabled"
			type="radio"
			class="btn-check"
			[name]="uuid"
			[id]="uuid + '-' + item.value"
			autocomplete="off"
		>
		<label class="btn btn-light" [for]="uuid + '-' + item.value">
			@if (item.icon) {
				<span [class]="item.icon"></span>
			}
			<span>{{ item.label }}</span>
		</label>
	}
</div>
