import { booleanAttribute, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { _isNil } from 'majora/lodash';
import { OptionItem } from '../../models/option-item';

@Component({
	selector: 'maj-button-select-group',
	standalone: true,
	imports: [FormsModule],
	templateUrl: './button-select-group.component.html',
	styleUrls: ['./button-select-group.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: ButtonSelectGroupComponent,
		},
	],
})
export class ButtonSelectGroupComponent<T extends OptionItem<K>, K> implements ControlValueAccessor {

	@Input({required: true})
	items: T[] = [];

	/**
	 * If emitFullOption is set to true, the valueChanged event will emit the full OptionItem object instead of just the value.
	 * Likewise, the ngModel will be set to the full OptionItem object instead of just the value. This is particularly useful
	 * when working with forms and requests that expect a full "GandalfConstant" object instead of just a primitive value.
	 * When writing a value to the component when this option is set to "true", the value should be an OptionItem object.
	 * @default false
	 */
	@Input({transform: booleanAttribute})
	emitFullOption = false;

	@Output()
	valueChanged = new EventEmitter<T | K | null>();

	selectedOption: T | null = null;

	readonly uuid = uuidv4();

	touched = false;

	protected disabled = false;

	/* istanbul ignore next */
	onModelChange = (_newValue: T | K | null) => {};

	/* istanbul ignore next */
	onTouched = () => {};

	registerOnChange(fn: any): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	writeValue(newValue: T | K): void {
		if (_isNil(newValue)) {
			this.selectedOption = null;
			return;
		}

		if (this.emitFullOption) {
			if (typeof newValue != 'object') {
				console.error('ButtonSelectGroupComponent: emitFullOption is set to true, but the written value is not an object');
			} else {
				this.selectedOption = newValue as T;
			}
		} else {
			this.selectedOption = this.items.find((item) => item.value === newValue) || null;
		}
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	handleItemChange() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}

		if (this.emitFullOption) {
			this.onModelChange(this.selectedOption);
			this.valueChanged.emit(this.selectedOption);
		} else {
			const value = _isNil(this.selectedOption) ? null : this.selectedOption!.value;
			this.onModelChange(value);
			this.valueChanged.emit(value);
		}
	}

}
