// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminLocationOrderProcessingVisionwebRequest } from './admin-location-order-processing-visionweb-request';

/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationOrderProcessingRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationOrderProcessingRequest extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	arrellioEnabled!: boolean | null;

	@GandalfLabel('Arrellio is Default')
	@GandalfValidator({ notNull: { message: 'Arrellio is Default is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	arrellioIsDefault!: boolean;

	@GandalfProperty({ designType: Boolean })
	eyefinityVspEnabled!: boolean | null;

	@GandalfValidator({ notNull: { message: 'SmartFlow Status is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	smartflowEnabled!: boolean;

	@GandalfValidator({ notNull: { message: 'VisionWeb Status is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	visionwebEnabled!: boolean;

	@GandalfProperty({ designType: AdminLocationOrderProcessingVisionwebRequest })
	visionwebInfo!: AdminLocationOrderProcessingVisionwebRequest | null;

	@GandalfProperty({ designType: Boolean })
	vspEstimatorEnabled!: boolean | null;

}
