import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { RouterStoreUtils } from '@app-store/utils/router-store-utils';
import { _map } from '@core/lodash/lodash';
import { RouterUtilsService } from '@core/router-utils/router-utils.service';
import { BasePanelMenuItem } from '@shared/component/panel-menu/base-panel-menu-item';
import { PanelMenuSubComponent } from '@shared/component/panel-menu/panel-menu-sub.component';

export interface PanelMenuItem {
	label?: string;
	icon?: string;
	command?: (event?: any) => void;
	url?: string;
	routerLink?: any;
	passiveRouterLink?: string;
	queryParams?: {
		[k: string]: any;
	};
	items?: PanelMenuItem[] | PanelMenuItem[][];
	expanded?: boolean;
	disabled?: boolean;
	visible?: boolean;
	target?: string;
	routerLinkActiveOptions?: any;
	separator?: boolean;
	badge?: string;
	badgeStyleClass?: string;
	style?: any;
	styleClass?: string;
	title?: string;
	id?: string;
	automationId?: any;
	dragValue?: string;
}

@Component({
	selector: 'pms-panel-menu',
	templateUrl: './panel-menu.component.html',
	styles: [],
	animations: [
		trigger('rootItem', [
			state('hidden', style({
				height: '0px',
			})),
			state('visible', style({
				height: '*',
			})),
			transition('visible => hidden', animate('{{transitionParams}}')),
			transition('hidden => visible', animate('{{transitionParams}}')),
		]),
	],
})
export class PanelMenuComponent extends BasePanelMenuItem implements OnChanges, OnInit {
	@ViewChildren(PanelMenuSubComponent) subMenuComponents: QueryList<PanelMenuSubComponent>;

	@Input() model: PanelMenuItem[];

	@Input() style: any;

	@Input() styleClass: string;

	@Input() multiple = false;

	@Input() transitionOptions = '0ms';

	@Input() noRouterLinks = false;

	@Output() headerClicked = new EventEmitter();

	animating: boolean;
	selectedItem: PanelMenuItem;

	constructor(
		private routerStoreUtils: RouterStoreUtils,
		private routerUtilsService: RouterUtilsService,
	) {
		super();
	}

	ngOnInit() {
		this.routerUtilsService.observeNavigationEvents(NavigationEnd)
			.subscribe(() => this.syncMenuWithRouter());
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.model) {
			this.syncMenuWithRouter();
		}
	}

	syncMenuWithRouter() {
		this.model.forEach(item => {
			const currentRoute = this.routerStoreUtils.getCurrentRoute();
			this.expandIfRouteActive(item, currentRoute);
		});
	}

	expandIfRouteActive(item: PanelMenuItem, currentRoute: string) {
		if (this.noRouterLinks) {
			return false;
		}
		if (item.items && item.items.length > 0) {
			item.expanded = _map(item.items, (subItem: PanelMenuItem) => {
				if (subItem.items && subItem.items.length > 0) {
					return this.expandIfRouteActive(subItem, currentRoute);
				} else {
					const routeToMatch = subItem.routerLink || subItem.passiveRouterLink;
					return routeToMatch ? this.routerUtilsService.isCurrentRouteChildRoute(currentRoute, routeToMatch) : false;
				}
			}).includes(true);

			// This is required for the recursive functionality to work properly.
			return item.expanded;
		}
	}

	collapseAll() {
		for (const item of this.model) {
			if (item.expanded) {
				item.expanded = false;
			}
		}
	}

	collapseAllExcept(item) {
		if (!this.multiple) {
			for (const modelItem of this.model) {
				if (item !== modelItem && modelItem.expanded) {
					modelItem.expanded = false;
				}
			}
		}
	}

	handleClick(event, item) {
		if (!item.routerLink) {
			this.headerClicked.emit(item);
		}

		this.collapseAllExcept(item);
		this.animating = true;
		super.handleClick(event, item);
	}

	onToggleDone() {
		this.animating = false;
	}

	onItemSelection(selectedItem) {
		this.selectedItem = selectedItem;
	}

}
