import { Component, TemplateRef } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NgTemplateOutlet } from '@angular/common';

@Component({
	standalone: true,
	selector: 'maj-template-cell-renderer',
	template: `<ng-container *ngTemplateOutlet="template; context: templateContext"></ng-container>`,
	imports: [NgTemplateOutlet],
})
export class TemplateCellRendererComponent implements AgRendererComponent {
	template!: TemplateRef<any>;
	templateContext!: { $implicit: any, params: any };

	refresh(params: any): boolean {
		this.templateContext = {
			$implicit: params.data,
			params,
		};
		return true;
	}

	agInit(params: ICellRendererParams & { ngTemplate: () => TemplateRef<any> }): void {
		// template function safe to evaluate here since the grid and templates should be initialized by this point
		this.template = params.ngTemplate();
		this.refresh(params);
	}
}
