import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ValidatorUtils } from './validator-utils';

/**
 * This validator allows one to apply validations on a form element using a lamda functions.
 * This can be useful for when something outside the form causes a field to be invalid.
 * Note: The validations for the formgroup must run for this to update.
 * @param assertion - Lamda returning a boolean, true indicating that the assertion is true and valid.
 * @param controls - Controls to apply the errors to.
 * @param errorProperty - The unique validation name for this validation.
 * @param message - The message to display on the group when this is invalid.
 */
export const assertTrue =
	(assertion: (FormGroup: any) => boolean, controls: string[], errorProperty: string, message: string) =>
		(group: UntypedFormGroup): ValidationErrors | null => {

			const isTrue = assertion(group);

			if (isTrue) {
				// delete control errors
				ValidatorUtils.removeErrorPropertyFromControls(controls, group, errorProperty);
				// delete group errors
				return ValidatorUtils.deleteErrorPropertyFromGroup(group, errorProperty);
			} else {
				// add control errors
				ValidatorUtils.addErrorPropertyToControls(controls, group, message, errorProperty);
				// return group errors
				const returnErrors: any = {};
				returnErrors[errorProperty] = {message};
				return returnErrors;
			}
		};
