<div class="modal-header">
	<h1 class="modal-title fs-5">{{ data.title }}</h1>
	<button type="button" class="btn-close" (click)="close(ConfirmModalResult.CANCELED)"></button>
</div>
<div class="modal-body" [style.word-break]="data.breakWords ? 'break-all': 'normal' ">
	{{ data.body }}
</div>
<div class="modal-footer">
	@if (!data.cancelButton?.hidden) {
		<button class="btn btn-sm btn-light" (click)="close(ConfirmModalResult.CANCELED)">
			{{ data.cancelButton?.text ?? 'No' }}
		</button>
	}

	@if (!data.affirmButton?.hidden) {
		<button class="btn btn-sm" [ngClass]="data.affirmButton?.style ?? 'btn-primary'" (click)="close(ConfirmModalResult.AFFIRMATIVE)">
			@if (data.affirmButton?.icon) {
				<i class="fa me-1" [ngClass]="data.affirmButton!!.icon"></i>
			}
			{{ data.affirmButton?.text ?? 'Yes' }}
		</button>
	}
</div>
