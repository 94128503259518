<ul class="ui-submenu-list" [@submenu]="expanded ? {value: 'visible', params: {transitionParams: transitionOptions}} : {value: 'hidden', params: {transitionParams: transitionOptions}}">
	<ng-template ngFor let-child [ngForOf]="item.items">
		<li *ngIf="child.separator" class="ui-menu-separator ui-widget-content">
		<li *ngIf="!child.separator" class="ui-menuitem ui-corner-all" [ngClass]="child.styleClass" [class.ui-helper-hidden]="child.visible === false" [ngStyle]="child.style">
			<a *ngIf="!child.routerLink" [href]="child.url||'#'" class="ui-menuitem-link ui-corner-all" [attr.tabindex]="item.expanded ? null : '-1'" [attr.id]="child.id"
			   [ngClass]="{'ui-state-disabled':child.disabled, 'ui-state-active': isActive(child)}"
			   (click)="handleClick($event,child)" [attr.target]="child.target" [attr.title]="child.title" (dragstart)="onDragStart($event, child)"
			   [attr.data-test-id]="child.automationId">
                        <span class="ui-panelmenu-icon fa" [ngClass]="{'fa-caret-right':!child.expanded,'fa-caret-down':child.expanded}" *ngIf="child.items"></span
						><span class="ui-menuitem-icon" [ngClass]="child.icon" *ngIf="child.icon"></span
			><span class="ui-menuitem-text">{{child.label}}</span>
			</a>
			<a *ngIf="child.routerLink" [routerLink]="child.routerLink" [queryParams]="child.queryParams" [routerLinkActive]="'ui-state-active'" [routerLinkActiveOptions]="child.routerLinkActiveOptions||{exact:false}" class="ui-menuitem-link ui-corner-all"
			   [ngClass]="{'ui-state-disabled':child.disabled}" [attr.tabindex]="item.expanded ? null : '-1'" [attr.id]="child.id"
			   (click)="handleClick($event,child)" [attr.target]="child.target" [attr.title]="child.title" [attr.data-test-id]="child.automationId">
                        <span class="ui-panelmenu-icon fa" [ngClass]="{'fa-caret-right':!child.expanded,'fa-caret-down':child.expanded}" *ngIf="child.items"></span
						><span class="ui-menuitem-icon" [ngClass]="child.icon" *ngIf="child.icon"></span
			><span class="ui-menuitem-text">{{child.label}}</span>
			</a>
			<pms-panel-menu-sub [selectedItem]="selectedItem" (itemSelected)="onItemSelected($event)" [noRouterLinks]="noRouterLinks" [item]="child" [expanded]="child.expanded" [transitionOptions]="transitionOptions" *ngIf="child.items"></pms-panel-menu-sub>
		</li>
	</ng-template>
</ul>
