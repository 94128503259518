<div class="input-group" [ngClass]="divClasses">
	<input
		#datePickerInput
		#datePicker="ngbDatepicker"
		ngbDatepicker
		class="form-control"
		(focus)="setPreviousValue()"
		(input)="handleDateInput()"
		[formControl]="control"
		[minDate]="{year: 1900, month: 1, day: 1}"
		[maxDate]="{year: 2099, month: 12, day: 31}"
		[footerTemplate]="footer"/>
	<button
		type="button"
		class="btn btn-light"
		[class.ng-invalid]="control.invalid"
		(click)="datePicker.toggle()">
		<i class="fa fa-calendar"></i>
	</button>
</div>

<ng-template #footer>
	<button class="btn btn-primary mb-1 ms-1" type="button" (click)="setToday(); datePicker.toggle()">Today</button>
</ng-template>
