<ejs-dialog #dialog
			cssClass="modal-md"
			(close)="close()"
			[attr.data-test-id]="'importQueriesModal'">
	<ng-template #header>
		<div class="dlg-template">Import Queries</div>
	</ng-template>
	<ng-template #content>
		<form id="importQueriesForm" class="form-horizontal" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="importQueries()">
			@if (formGroup.invalid && form.submitted) {
				<gandalf-lib-validation-messages
					[form]="formGroup"
					[requestObj]="request">
				</gandalf-lib-validation-messages>
			}
			<div class="row">
				<div class="col-sm-12">
					<rev-ag-grid-container [agGrid]="agGrid" [showToolbar]="false">
						<ng-container rev-container-grid>
							<ag-grid-angular
								#agGrid
								pmsAgAutoFitColumns
								class="ag-theme-alpine"
								rowClass="row-link"
								rowSelection="multiple"
								rowMultiSelectWithClick="true"
								[pmsAgGridSubmit]="isSearching"
								(selectionChanged)="updateSelectedQueries()"
								[attr.data-test-id]="'importQueriesTable'"
								[columnDefs]="gridColumns"
								[rowData]="masterQueries">
							</ag-grid-angular>
						</ng-container>
					</rev-ag-grid-container>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button (click)="close()" iconCss="fa fa-times" [attr.data-test-id]="'importQueriesCancelButton'">Cancel</button>
		<button form="importQueriesForm" type="submit" ejs-button [isPrimary]="true" [attr.data-test-id]="'importQueriesImportButton'">Import</button>
	</ng-template>
</ejs-dialog>
