import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'maj-date-picker',
	standalone: true,
	imports: [NgbInputDatepicker, ReactiveFormsModule, CommonModule],
	templateUrl: './date-picker.component.html',
})
export class DatePickerComponent {

	@Input({required: false})
	divClasses: string = '';

	@Input({required: true})
	control!: FormControl<Date | null>;

	@ViewChild('datePicker')
	datePicker!: NgbInputDatepicker;

	@ViewChild('datePickerInput')
	datePickerInput!: ElementRef<HTMLInputElement>;

	previousValue: string = '';

	setToday() {
		const today: Date = this.getToday();
		today.setHours(0, 0, 0, 0);
		this.control.setValue(today);
	}

	getToday(): Date {
		return new Date();
	}

	setPreviousValue() {
		this.previousValue = this.datePickerInput.nativeElement.value ?? '';
	}

	handleDateInput() {
		const value = this.datePickerInput.nativeElement.value ?? '';
		if (value) {
			const newValue = this.processValue(value, this.previousValue);
			if (newValue !== value) {
				this.datePicker.manualDateChange(newValue, true);
				this.datePickerInput.nativeElement.value = newValue;
			}
			this.previousValue = newValue;
		}
	}

	private processValue(currentValue: string, previousValue: string) {
		// If any character is not a digit or / then return previousValue
		const characterRestrictionRegex = /^[0-9/]*$/;
		if (!currentValue.match(characterRestrictionRegex)) {
			return previousValue;
		}

		// If length is 2 or 5 add a /
		// if length is 3 or 6 and 2 or 5 is not / insert it in the correct spot
		if (previousValue?.slice(-1) !== '/') {
			if (currentValue?.length === 2 || currentValue?.length === 5) {
				currentValue += '/';
			} else if (currentValue?.length === 3 && currentValue[2] !== '/') {
				currentValue = currentValue.substring(0, 2) + '/' + currentValue.slice(-1);
			} else if (currentValue?.length === 6 && currentValue[5] !== '/') {
				currentValue = currentValue.substring(0, 5) + '/' + currentValue.slice(-1);
			}
		}

		// Check if value matches the pattern dd/dd/dddd if it doesn't return previous value
		const validString = '00/00/0000';
		const lengthDiff = validString.length - currentValue.length;
		const amalgamation = currentValue + (lengthDiff <= 0 ? '' : validString.substring(validString.length - lengthDiff));

		const patternRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
		if (!amalgamation.match(patternRegex)) {
			return previousValue;
		}
		return currentValue;
	}
}
