import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidatorUtils } from './validator-utils';
const ERROR_PROPERTY = 'atLeastOne';

/**
 * @param validator At least one of the controls must pass this validation
 * @param controls The controls that we require at least one to be valid
 * @param message Error message to use
 * @param controlForError The control to add the error to (if not specified, it is added to each control in 'controls')
 */
export const atLeastOne = (validator: ValidatorFn, controls: string[] | null = null, message: string, controlForError?: string) => (
	group: UntypedFormGroup,
): ValidationErrors | null => {

	/*istanbul ignore next*/
	if (!controls) {
		controls = Object.keys(group.controls);
	}

	/*istanbul ignore next*/
	const hasAtLeastOne = group && group.controls && controls
		.some(k => !validator(group.controls[k]));

	const controlsForErrors: string[] = controlForError ? [controlForError] : controls;

	/*istanbul ignore next*/
	if (hasAtLeastOne) {
		// delete control errors
		ValidatorUtils.removeErrorPropertyFromControls(controlsForErrors, group, ERROR_PROPERTY);
		// delete group errors
		return ValidatorUtils.deleteErrorPropertyFromGroup(group, ERROR_PROPERTY);
	} else {
		// add control errors
		ValidatorUtils.addErrorPropertyToControls(controlsForErrors, group, message, ERROR_PROPERTY);
		// return group errors
		return {
			atLeastOne: {message},
		};
	}
};

