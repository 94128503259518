import { Injectable } from '@angular/core';
import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {_isInteger} from 'majora/lodash';

@Injectable()
export class RevNgbDateNativeAdapter extends NgbDateAdapter<Date> {

	fromModel(date: Date | null): NgbDateStruct | null {
		return date instanceof Date && !isNaN(date.getTime()) ? this._fromNativeDate(date) : null;
	}

	toModel(date: NgbDateStruct | null): Date | null {
		return date && _isInteger(date.year) && _isInteger(date.month) && _isInteger(date.day)
			? this._toNativeDate(date)
			: null;
	}

	protected _fromNativeDate(date: Date): NgbDateStruct {
		return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
	}

	protected _toNativeDate(date: NgbDateStruct): Date {
		const jsDate = new Date(date.year, date.month - 1, date.day);
		// avoid 30 -> 1930 conversion
		jsDate.setFullYear(date.year);
		return jsDate;
	}
}
