import { NgTemplateOutlet } from '@angular/common';
import { Component, TemplateRef } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
	standalone: true,
	selector: 'maj-tooltip-cell-renderer',
	template: `
		<div class="custom-tooltip" *ngTemplateOutlet="template; context: templateContext"></div>`,
	styles: [
		`
			:host {
				position: absolute;
			}

			:host.ag-tooltip-hiding {
				opacity: 0;
			}

			.custom-tooltip p {
				margin: 5px;
				white-space: nowrap;
			}

			.custom-tooltip p:first-of-type {
				font-weight: bold;
			}
		`,
	],
	imports: [NgTemplateOutlet],
})
export class TooltipCellRendererComponent implements ITooltipAngularComp {
	template!: TemplateRef<any>;
	templateContext!: { $implicit: any, params: any };

	refresh(params: any): boolean {
		this.templateContext = {
			$implicit: params.data,
			params,
		};
		return true;
	}

	agInit(params: ITooltipParams & { ngTemplate: () => TemplateRef<any> }): void {
		// template function safe to evaluate here since the grid and templates should be initialized by this point
		this.template = params.ngTemplate();
		this.refresh(params);
	}
}
