import { Injectable, Type } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent, ConfirmModalData } from '../../components/modal/confirm-modal/confirm-modal.component';
import { ModalBase } from '../../models/modal/ModalBase';
import { ModalRef } from '../../models/modal/ModalRef';

@Injectable({providedIn: 'root'})
export class ModalManagerService {

	constructor(
		private ngbModal: NgbModal,
	) {
	}

	open<T, R>(componentType: Type<ModalBase<T, R>>, data: T, modalOptions: NgbModalOptions): ModalRef<T, R> {
		const modalRef: ModalRef<T, R> = this.ngbModal.open(componentType, modalOptions);
		modalRef.componentInstance.data = data;
		const originalResultPromise = modalRef.result;
		modalRef.result = new Promise((resolve) => {
			originalResultPromise.then((result) => {
				resolve(result);
			}).catch((_error) => {
				resolve(undefined);
			});
		});
		return modalRef;
	}

	confirm(data: ConfirmModalData) {
		return this.open(ConfirmModalComponent, data, {size: 'sm', backdrop: 'static'});
	}

	alert(title: string, body: string) {
		return this.open(
			ConfirmModalComponent,
			{ title, body, affirmButton: { hidden: true }, cancelButton: { text: 'Close' }, breakWords: true },
			{ size: 'md', backdrop: 'static', keyboard: false },
		);
	}
}
