import { ColDef, ColDefField, GridOptions, ProcessCellForExportParams, provideGlobalGridOptions, ValueGetterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { TemplateRef } from '@angular/core';
import { _isEmpty, _isNil } from 'majora/lodash';
import { TemplateCellRendererComponent } from '../components/template-cell-renderer/template-cell-renderer.component';
import { TooltipCellRendererComponent } from '../components/tooltip-cell-renderer/tooltip-cell-renderer.component';
import { OptionItem } from '../models/option-item';
import { ConditionalHyperlinkCellRendererComponent } from '../components/conditional-hyperlink-cell-renderer/conditional-hyperlink-cell-renderer.component';

export const STRING_COMPARISON_FILTER = 'agTextColumnFilter';
export const NUMBER_COMPARISON_FILTER = 'agNumberColumnFilter';

export type RevColDef<TData> = ColDef<TData> & {
	suppressExport?: boolean;
	exportOnly?: boolean;
	printWidth?: number;
}

export class GridUtil {

	static buildColumn<TData>(headerName: string, field: ColDefField<TData>, overrides?: RevColDef<TData>): RevColDef<TData> {
		return this.applyOverrides({headerName, field, filter: STRING_COMPARISON_FILTER}, overrides);
	}

	static buildFlexColumn<TData>(headerName: string, field: ColDefField<TData>, overrides?: RevColDef<TData>): RevColDef<TData> {
		return this.applyOverrides({headerName, field, tooltipField: field, flex: 1, filter: STRING_COMPARISON_FILTER}, overrides);
	}

	static buildNumericColumn<TData>(headerName: string, field: ColDefField<TData>, overrides?: RevColDef<TData>): RevColDef<TData> {
		return this.applyOverrides({
			headerName,
			field,
			type: 'numericColumn',
			filter: NUMBER_COMPARISON_FILTER,
		}, overrides);
	}

	/**
	 * Builds a column with a template renderer that IS sortable and exportable by using the field value
	 */
	static buildFieldTemplateColumn<TData>(headerName: string, field: ColDefField<TData>, templateRef: () => TemplateRef<any>, overrides?: RevColDef<TData>): RevColDef<TData> {
		return this.applyOverrides({
			headerName,
			field,
			cellRenderer: TemplateCellRendererComponent,
			cellRendererParams: {
				ngTemplate: templateRef,
			},
			filter: STRING_COMPARISON_FILTER,
		}, overrides);
	}

	/**
	 * Builds a column with a tooltip renderer
	 */
	static buildTooltipTemplateColumn<TData>(headerName: string, field: ColDefField<TData>, templateRef: () => TemplateRef<any>, overrides?: RevColDef<TData>): RevColDef<TData> {
		return this.applyOverrides({
			headerName,
			field,
			tooltipField: field,
			tooltipComponent: TooltipCellRendererComponent,
			tooltipComponentParams: {
				ngTemplate: templateRef,
			},
		}, overrides);
	}

	/**
	 * Builds a column with a template renderer that IS NOT sortable or exportable
	 */
	static buildTemplateColumn<TData>(headerName: string, templateRef: () => TemplateRef<any>, overrides?: RevColDef<TData>): RevColDef<TData> {
		return this.applyOverrides({
			headerName,
			cellRenderer: TemplateCellRendererComponent,
			cellRendererParams: {
				ngTemplate: templateRef,
			},
			sortable: false,
			suppressExport: true,
		}, overrides);
	}

	static buildHyperlinkColumn<TData>(
		headerName: string,
		field: ColDefField<TData>,
		onLinkClicked: (rowData: TData) => void,
		showAsLink?: (rowData: TData) => boolean,
		overrides?: RevColDef<TData>,
	): RevColDef<TData> {
		return this.applyOverrides({
			headerName,
			field,
			cellRenderer: ConditionalHyperlinkCellRendererComponent,
			cellRendererParams: {
				showAsLink,
				onLinkClicked,
			},
			filter: STRING_COMPARISON_FILTER,
		}, overrides);
	}

	static buildBooleanColumn<TData>(
		headerName: string,
		field: ColDefField<TData>,
		overrides?: RevColDef<TData>,
		trueText: string = 'Yes',
		falseText: string = 'No',
	): RevColDef<TData> {
		return this.applyOverrides({
			headerName,
			field,
			valueGetter: (params: ValueGetterParams) => params.data![field] ? trueText : falseText,
			filter: STRING_COMPARISON_FILTER,
		} as RevColDef<TData>, overrides);
	}

	static buildCheckboxSelectionColumn<TData>(overrides?: RevColDef<TData>) {
		return this.applyOverrides({
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			checkboxSelection: true,
			sortable: false,
			suppressExport: true,
			lockPosition: true,
			width: 37,
			resizable: false,
		}, overrides);
	}

	static applyOverrides<TData>(column: RevColDef<TData>, overrides?: RevColDef<TData>): RevColDef<TData> {
		column = Object.assign(column, overrides);

		// Hide the columns that will only be used in export
		if (column.exportOnly) {
			column.hide = true;
		}

		return column;
	}

	/**
	 * Applies filter changes for an Enum column by either adding or clearing the filter, based on the outcome of the `applyIf` method
	 * @param agGrid the grid being filtered
	 * @param applyIf a method which returns `true` if the filter should be added or `false` if it should be cleared
	 * @param columnName the Enum column to be filtered
	 * @param filterValue the Enum value used for the filter
	 * @param filterType the type of filter to be applied, defaults to `equals`
	 * @param applyImmediate if `true` the filter will be applied immediately by refreshing the grid
	 */
	static applyEnumFilter(
		agGrid: AgGridAngular,
		applyIf: () => boolean,
		columnName: string,
		filterValue: OptionItem<any>,
		filterType: string = 'equals',
		applyImmediate = true,
	) {
		GridUtil.applyFilter(agGrid, applyIf, columnName, filterValue?.label, filterType, applyImmediate);
	}

	/**
	 * Applies filter changes for a text column by either adding or clearing the filter, based on the outcome of the `applyIf` method
	 * @param agGrid the grid being filtered
	 * @param applyIf a method which returns `true` if the filter should be added or `false` if it should be cleared
	 * @param columnName the Enum column to be filtered
	 * @param text the text value used for the filter
	 * @param applyImmediate if `true` the filter will be applied immediately by refreshing the grid
	 */
	static applyTextContainsFilter(
		agGrid: AgGridAngular,
		applyIf: () => boolean,
		columnName: string,
		text: string,
		applyImmediate = true,
	) {
		GridUtil.applyFilter(agGrid, applyIf, columnName, text, 'contains', applyImmediate);
	}

	/**
	 * Applies filter changes for a column by either adding or clearing the filter, based on the outcome of the `applyIf` method
	 * @param agGrid the grid being filtered
	 * @param applyIf a method which returns `true` if the filter should be added or `false` if it should be cleared
	 * @param columnName the column to be filtered
	 * @param filterValue the value used for the filter
	 * @param filterType the type of filter to be applied, defaults to `equals`
	 * @param applyImmediate if `true` the filter will be applied immediately by refreshing the grid
	 */
	static applyFilter(
		agGrid: AgGridAngular,
		applyIf: () => boolean,
		columnName: string,
		filterValue: any,
		filterType: string = 'equals',
		applyImmediate = true,
	) {
		if (!GridUtil.isGridFilterReady(agGrid)) {
			return;
		}

		agGrid?.api?.setFilterModel({
			...agGrid.api.getFilterModel(), ...{
				[columnName]: applyIf() ? {
					type: filterType,
					filter: filterValue,
				} : {},
			},
		});

		if (applyImmediate) {
			agGrid?.api?.onFilterChanged();
		}
	}

	static isGridFilterReady(agGrid: AgGridAngular): boolean {
		return !_isEmpty(agGrid?.api?.getColumnDefs());
	}

	static exportAGGridToCsv<TData>(grid: AgGridAngular<TData, RevColDef<TData>>) {
		// Grab all column keys, including those that are export only, excluding those that are should be suppressed for export
		const columnKeys = (grid.api.getColumnDefs() as RevColDef<TData>[])
			?.filter(col => !col.suppressExport)
			.map(col => col.colId);

		grid.api.exportDataAsCsv({processCellCallback: GridUtil.processExportData, columnKeys: columnKeys as string[]});
	}

	static processExportData = (params: ProcessCellForExportParams) => {
		if (!_isNil(params.column.getUserProvidedColDef()?.valueFormatter)) {
			return GridUtil.sanitizeCellDataForExport((params.column.getUserProvidedColDef()?.valueFormatter as any)(params));
		}
		return GridUtil.sanitizeCellDataForExport(params.value);
	};

	/**
	 * Sanitizes the data in a grid cell for exporting
	 */
	static sanitizeCellDataForExport(value: any) {
		if (typeof value === 'string' || value instanceof String) {
			return value.replace(/^[=@\\|"]+/g, '');
		}
		return value;
	}

	/**
	 * Initializes global grid options
	 * @param overrides - optional overrides for the default grid options
	 */
	static initGlobalGridOptions(overrides?: GridOptions) {
		provideGlobalGridOptions(Object.assign({
			animateRows: false,
			alignedGrids: [],
			enterNavigatesVertically: true,
			enterNavigatesVerticallyAfterEdit: true,
			enableCellTextSelection: false,
			stopEditingWhenCellsLoseFocus: true,
			singleClickEdit: true,
			tooltipShowDelay: 300,
			pagination: true,
			suppressPaginationPanel: false,
			paginationPageSize: 10,
			paginationPageSizeSelector: [10, 20, 30, 40, 50, 100],
			domLayout: 'autoHeight',
			popupParent: document.querySelector('body'),
			overlayNoRowsTemplate: `<span>No records to display</span>`,
			defaultColDef: {
				suppressMovable: true,
				suppressHeaderFilterButton: true,
				suppressHeaderMenuButton: true,
			},
			suppressRowVirtualisation: false,
			suppressColumnVirtualisation: false,
		}, overrides));
	}

}
