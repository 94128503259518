import { Directive, OnInit } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { from } from 'rxjs';

@Directive({
	selector: 'ag-grid-angular[pmsAgAutoFitColumns]',
})
export class AgAutoFitColumnsDirective implements OnInit {

	constructor(private grid: AgGridAngular) {
	}

	ngOnInit() {
		this.observeRowData();
	}

	observeRowData() {
		from(this.grid.modelUpdated).subscribe(() => this.autoFitColumns());
		from(this.grid.rowDataUpdated).subscribe(() => this.autoFitColumns());
		from(this.grid.gridReady).subscribe(() => this.autoFitColumns());
	}

	autoFitColumns() {
		const columns = this.grid.api.getColumns();

		const columnsToAutoFit = columns?.filter(column => this.shouldAutoSize(column.getColDef())).map(column => column.getColDef().field);

		this.grid.api.autoSizeColumns(columnsToAutoFit);
	}

	private shouldAutoSize(colDef: ColDef) {
		return _isNil(colDef.flex) && _isNil(colDef.width) && !colDef.hide;
	}

}
