import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterStoreUtils } from '@app-store/utils/router-store-utils';
import { _isNil, _isUndefined } from '@core/lodash/lodash';
import { RouterUtilsService } from '@core/router-utils/router-utils.service';
import { BasePanelMenuItem } from '@shared/component/panel-menu/base-panel-menu-item';
import { PanelMenuItem } from '@shared/component/panel-menu/panel-menu.component';

@Component({
	selector: 'pms-panel-menu-sub',
	templateUrl: './panel-menu-sub.component.html',
	styles: [],
	animations: [
		trigger('submenu', [
			state('hidden', style({
				height: '0px',
			})),
			state('visible', style({
				height: '*',
			})),
			transition('visible => hidden', animate('{{transitionParams}}')),
			transition('hidden => visible', animate('{{transitionParams}}')),
		]),
	],
})
export class PanelMenuSubComponent extends BasePanelMenuItem {

	@Input() transitionOptions: string;

	@Input() item: PanelMenuItem;

	@Input() expanded: boolean;

	@Input() noRouterLinks = false;

	@Output()
	itemSelected = new EventEmitter<PanelMenuItem>();

	@Input()
	selectedItem: PanelMenuItem;

	constructor(
		private routerUtilsService: RouterUtilsService,
		private routerStoreUtils: RouterStoreUtils,
	) {
		super();
	}

	/**
	 * Is Current Route Child Route
	 * Check whether or not the currently selected item's route is a child route of the
	 * current route. This is used primarily to apply the active class onto the element.
	 * @param route Route to use to check against the current route.
	 */
	isCurrentRouteChildRoute(route: string) {
		if (_isUndefined(route)) {
			return false;
		}

		const currentRoute = this.routerStoreUtils.getCurrentRoute();
		return this.routerUtilsService.isCurrentRouteChildRoute(currentRoute, route);
	}

	isActive(child) {
		return this.isCurrentRouteChildRoute(child.passiveRouterLink) || (this.noRouterLinks && this.selectedItem === child);
	}

	handleClick(event, item) {
		super.handleClick(event, item);
		this.onItemSelected(item);
	}

	onItemSelected(selectedItem) {
		this.selectedItem = selectedItem;
		this.itemSelected.emit(selectedItem);
	}

	onDragStart(event: DragEvent, item: PanelMenuItem) {
		if (!_isNil(item.dragValue)) {
			event.dataTransfer.setData('text/html', item.dragValue);
		}
	}
}
